import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { getLocalStorageItem } from '../utilities/localStorage.util';

const ERROR_MONITORING = {
  X_TRACE_ID: 'X-TRACE_ID',
  TRACE_ID: 'traceId',
};

export const interceptErrorResponse = (error) => {
  throw error;
};

export const interceptAuthRequest = (config) => {
  if (config && config.headers) {
    config.headers[ERROR_MONITORING.X_TRACE_ID] = uuid();
  }

  const credentials = getLocalStorageItem<AdminLoginResponseDto>(
    'doctors_portal_user'
  );

  const token = credentials.access_token;

  config.headers = {
    ...config.headers,
    credentials: 'include',
  };

  if (token) {
    config.headers = {
      ...config.headers,
      withCredentials: true,
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
};

export const setTraceId = (config) => {
  if (!config.headers || !config.headers[ERROR_MONITORING.X_TRACE_ID]) {
    config.headers = {
      ...config.headers,
      [ERROR_MONITORING.X_TRACE_ID]: uuid(),
    };
  }

  return config;
};

export const createApiClient = (baseURL, interceptResponse = interceptAuthRequest) => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use((res) => res, interceptErrorResponse);

  axiosInstance.interceptors.request.use(
    interceptResponse,
    interceptErrorResponse
  );

  return {
    axiosInstance,
    use: (api) => new api(undefined, baseURL, axiosInstance),
  };
};

const ApiClient = createApiClient('', interceptAuthRequest);

export default ApiClient;