import React, { Component, Suspense, lazy, Fragment } from 'react';
import { Route } from 'react-router-dom';

import AgentNavbar from '../organisms/AgentNavbar';
import AuthTemplate from './AuthTemplate';
import UserProvider from './UserContext';
import ShipmentsProvider from './ShipmentsContext';
import { ROLE_AGENT } from '../../utilities/constants';
import UsersProvider from './UsersContext';
import SalesProvider from './SalesContext';
import AddUsers from '../pages/agent/AddUsers';
import EditUsers from '../pages/agent/EditUsers';

const Shipments = lazy(() => import('../pages/agent/shipment'));
const ProofOfDelivery = lazy(() => import('../pages/agent/ProofOfDelivery'));
const Users = lazy(() => import('../pages/agent/users/Users'));
const Sales = lazy(() => import('../pages/agent/sales/Sales'));

class AgentTemplate extends Component {
  render() {
    return (
      <Fragment>
        <UserProvider>
          <AgentNavbar />
          <div
            className="container-fluid parallax text-center container-has-secondary-navbar"
            id="top"
          >
            <Suspense
              fallback={<div style={{ marginTop: '100px' }}>Loading...</div>}
            >
              <UsersProvider>
                <Route
                  exact
                  path="/agent/clients"
                  component={AuthTemplate(Users, ROLE_AGENT)}
                />
              </UsersProvider>
              <Route
                exact
                path="/agent/clients/add"
                component={AuthTemplate(AddUsers, ROLE_AGENT)}
              />
              <Route
                exact
                path="/agent/users/clients/:id"
                component={AuthTemplate(EditUsers, ROLE_AGENT)}
              />
              <ShipmentsProvider>
                <Route
                  exact
                  path="/agent/shipments"
                  component={AuthTemplate(Shipments, ROLE_AGENT)}
                />
                <Route
                  exact
                  path="/agent/shipments/:id/proof-of-delivery"
                  component={AuthTemplate(ProofOfDelivery, ROLE_AGENT)}
                />
              </ShipmentsProvider>
              <SalesProvider>
                <Route
                  exact
                  path="/agent/sales"
                  component={AuthTemplate(Sales, ROLE_AGENT)}
                />
              </SalesProvider>
            </Suspense>
          </div>
        </UserProvider>
      </Fragment>
    );
  }
}

export default AgentTemplate;
