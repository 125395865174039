import React, { Component, createContext } from 'react';

export const SalesContext = createContext({
  chart: {},
  shipmentCountPerStatus: {},
  shipmentSales: {},
  recentOrders: {},
  filters: {
    start_date: '',
    end_date: '',
    consignee_province: '',
    user_id: '',
  },
  provinces: [],
  users: {
    items: {},
    cursor: null,
  },
});

class SalesProvider extends Component {
  state = {
    chart: {},
    shipmentCountPerStatus: {},
    shipmentSales: {},
    filters: {
      start_date: '',
      end_date: '',
      consignee_province: '',
      user_id: '',
    },
    recentOrders: {},
    provinces: [],
    users: {
      items: {},
      cursor: null,
    },
  };

  setUsers = (users, cursor) => {
    let data = {};

    users.forEach(
      user =>
        (data[user.id] = {
          value: user.id,
          label: `${user.firstname} ${user.lastname}`,
        }),
    );

    this.setState({
      users: {
        items: {
          ...this.state.users.items,
          ...data,
        },
        cursor,
      },
    });
  };

  setRecentOrders = recentOrders => {
    this.setState({
      recentOrders,
    });
  };

  setChart = chart => {
    this.setState({
      chart,
    });
  };

  setShipmentCountPerStatus = shipmentCountPerStatus => {
    this.setState({
      shipmentCountPerStatus,
    });
  };

  setShipmentSales = shipmentSales => {
    this.setState({
      shipmentSales,
    });
  };

  setFilters = filters => {
    this.setState({
      filters,
    });
  };

  setProvinces = provinces => {
    let data = {};
    provinces.forEach(province => (data[province.value] = province));

    this.setState({
      provinces: data,
    });
  };

  render() {
    return (
      <SalesContext.Provider
        value={{
          sales: this.state,
          setShipmentCountPerStatus: this.setShipmentCountPerStatus,
          setShipmentSales: this.setShipmentSales,
          setUsers: this.setUsers,
          setChart: this.setChart,
          setFilters: this.setFilters,
          setProvinces: this.setProvinces,
          setRecentOrders: this.setRecentOrders,
        }}
      >
        {this.props.children}
      </SalesContext.Provider>
    );
  }
}

export default SalesProvider;
