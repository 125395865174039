import React, { createContext, useState } from 'react';
import { SHIPPING_ITEMS_DISPLAY_LIMIT } from '../../utilities/constants/shipping';

export const ShipmentsContext = createContext();

function ShipmentsProvider(props) {
  const [pageLength, setPageLength] = useState(1);
  const [shipments, setShipments] = useState({});
  const SHIPMENTS_LIMIT = SHIPPING_ITEMS_DISPLAY_LIMIT;

  const loadShipments = items => {
    let data = {};

    items.forEach(item => (data[item.id] = item));

    setShipments(data);
  };

  const updateShipment = item => {
    setShipments({ ...shipments, [item.id]: item });
  };

  return (
    <ShipmentsContext.Provider
      value={{
        loadShipments,
        pageLength,
        setPageLength,
        shipments,
        SHIPMENTS_LIMIT,
        updateShipment,
      }}
    >
      {props.children}
    </ShipmentsContext.Provider>
  );
}

export default ShipmentsProvider;
