import React from 'react';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const DateRangePicker = props => {
  const { onChange } = props;

  const handleChange = (_, dateRange) => {
    onChange(dateRange);
  };

  return (
    <RangePicker allowClear className="date-picker" onChange={handleChange} />
  );
};

export default DateRangePicker;
