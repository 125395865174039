import startCase from 'lodash-es/startCase';
import camelCase from 'lodash-es/camelCase';

export const toFormData = object_data => {
  const formData = new FormData();
  for (let [key, value] of Object.entries(object_data)) {
    formData.append(key, value);
  }
  return formData;
};

export const wordsSeparator = (addressLines, separator) => {
  // If user provides a custom separator as parameter, use that instead.
  // Default separator is ', '
  return addressLines.filter(Boolean).join(separator ? separator : ', ');
};

export const capitalizeStatement = (string, blacklist) => {
  // Capitalize first letter of every word in a statement except
  // for words in the blacklist
  return string.replace(/\w\S*/g, text => {
    let hasMatch = false;
    if (blacklist && blacklist.length > 0) {
      blacklist.forEach(word => {
        if (word.toLowerCase() === text.toLowerCase()) hasMatch = true;
      });
    }
    if (hasMatch) {
      return text.toLowerCase();
    } else {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    }
  });
};

export const testEmail = email => {
  // Test if valid email
  let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(email);
};

export const handleNumberRestriction = event => {
  ['e', 'E', '-', ';', ',', '#', '*'].includes(event.key) &&
    event.preventDefault();
};

export const imagesPreloader = imageList => {
  if (imageList && imageList.length > 0) {
    imageList.forEach(image => {
      new Image().src = image;
    });
  }
};

export const transformPascalCaseToWords = pascalCaseWord => {
  return startCase(camelCase(pascalCaseWord ?? ''));
};
