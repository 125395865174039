import React, { Component, Suspense, lazy, Fragment } from 'react';
import { Route } from 'react-router-dom';

import AdminNavbar from '../organisms/AdminNavbar';
import AuthTemplate from './AuthTemplate';
import UserProvider from './UserContext';
import UsersProvider from './UsersContext';
import ShipmentsProvider from './ShipmentsContext';
import { ROLE_ADMIN } from '../../utilities/constants';
import SalesProvider from "./SalesContext";
import Sales from "../pages/admin/sales/Sales";

const Users = lazy(() => import('../pages/admin/users'));
const AddUsers = lazy(() => import('../pages/admin/AddUsers'));
const EditUsers = lazy(() => import('../pages/admin/EditUsers'));
const Shipments = lazy(() => import('../pages/admin/shipments'));
const ProofOfDelivery = lazy(() => import('../pages/admin/ProofOfDelivery'));

class AdminTemplate extends Component {
  render() {
    return (
      <Fragment>
        <UserProvider>
          <AdminNavbar />
          <div id="top" className="container-fluid parallax text-center container-has-secondary-navbar">
            <Suspense
              fallback={<div style={{ marginTop: '100px' }}>Loading...</div>}
            >
              <UsersProvider>
                <Route
                  exact
                  path="/admin/users"
                  component={AuthTemplate(Users, ROLE_ADMIN)}
                />
                <Route
                  exact
                  path="/admin/users/add"
                  component={AuthTemplate(AddUsers, ROLE_ADMIN)}
                />
                <Route
                  exact
                  path="/admin/users/edit/:id"
                  component={AuthTemplate(EditUsers, ROLE_ADMIN)}
                />
              </UsersProvider>
              <ShipmentsProvider>
                <Route
                  exact
                  path="/admin/shipments"
                  component={AuthTemplate(Shipments, ROLE_ADMIN)}
                />
                <Route
                  exact
                  path="/admin/shipments/:id/proof-of-delivery"
                  component={AuthTemplate(ProofOfDelivery, ROLE_ADMIN)}
                />
              </ShipmentsProvider>
              <SalesProvider>
                <Route
                  exact
                  path="/admin/sales"
                  component={AuthTemplate(Sales, ROLE_ADMIN)}
                />
              </SalesProvider>
            </Suspense>
          </div>
        </UserProvider>
      </Fragment>
    );
  }
}

export default AdminTemplate;
