import React, { Component, createContext } from 'react';

export const UserContext = createContext();

class UserProvider extends Component {
  state = {
    userDetails: {},
  };

  componentDidMount() {
    let currentUser = localStorage.getItem('userDetails');
    if (currentUser) {
      this.setState({
        userDetails: JSON.parse(currentUser),
      });
    }
  }

  saveUserDetails = details => {
    localStorage.setItem('userDetails', JSON.stringify(details));
    this.setState({
      userDetails: details,
    });
  };

  removeUserDetails = () => {
    localStorage.removeItem('userDetails');
    this.setState({
      userDetails: {},
    });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          state: this.state,
          saveUserDetails: this.saveUserDetails,
          removeUserDetails: this.removeUserDetails,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
