import React from 'react';
import '../../assets/css/components/SecondaryNavbar.css';
import { Link as NavLink } from "react-router-dom";

function AdminSecondaryNavbar() {
  return (
    <nav className="secondary-navbar">
      <ul className="secondary-navbar-items">
				<li>
					<NavLink to="/admin/users">
						<span className="secondary-navbar-items-span">Users</span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/admin/shipments">
						<span className="secondary-navbar-items-span">Shipments</span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/admin/sales">
						<span className="secondary-navbar-items-span">Sales</span>
					</NavLink>
				</li>
      </ul>
    </nav>
  )
}

export default AdminSecondaryNavbar;