import React, { useEffect, useMemo, useState } from 'react';
import useForm from 'react-hook-form';
import { RegionDropdown } from 'react-country-region-selector';
import { COUNTRY_DOMESTIC } from '../../utilities/constants/shipping';
import { WHITELIST_REGION } from '../../utilities/constants/rates';
import InputCheckbox from '../atoms/InputCheckbox';
import { ROLE_ADMIN, ROLE_AGENT } from '../../utilities/constants';

function EditUserForm(props) {
  const { register, handleSubmit } = useForm();
  const {
    onSubmit,
    setRegion,
    region,
    toggleConfirmationModal,
    creatorRole,
  } = props;
  const user = useMemo(() => props.user || {}, [props.user]);
  console.log({ user });
  const require = register({ required: true });
  const [isAgent, setIsAgent] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const countryKey = 'ship_country';
  const country = localStorage.getItem(countryKey) || COUNTRY_DOMESTIC;
  const [isRegionAll, setIsRegionAll] = useState(false);
  const [commissionType, setCommissionType] = useState('flat');
  const [commission, setCommission] = useState(0);

  useEffect(() => {
    setIsAgent(user?.roles?.includes(ROLE_AGENT));
    setIsAdmin(user?.roles?.includes(ROLE_ADMIN));
  }, [user]);

  useEffect(() => {
    if (commissionType === 'percentage') {
      setCommission(Math.min(Math.max(commission, 0), 100));
    }
    if (commissionType === 'flat') {
      setCommission(Math.max(commission, 0));
    }
    if (commission === '') {
      setCommission('');
    }
  }, [commission, commissionType]);

  useEffect(() => {
    if (isAgent) {
      setIsRegionAll(true);
    } else {
      setIsRegionAll(false);
    }
  }, [isAgent]);

  useEffect(() => {
    if (user.region) {
      setRegion(user.region);
      if (user.region === 'ALL') {
        setIsRegionAll(true);
      }
    }

    if (user.commission) {
      setCommission(user.commission);
    }
    if (user.commission_type) {
      setCommissionType(user.commission_type);
    }
  }, [user]);

  useEffect(() => {
    if (isRegionAll) {
      setRegion('ALL');
    } else {
      setRegion('');
    }
  }, [isRegionAll]);
  return (
    <form className="sign-form" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="name">
        <h4>First name</h4>
      </label>
      <input
        className="form-control"
        defaultValue={user.firstname}
        id="firstname"
        name="firstname"
        ref={require}
        type="text"
      />

      <label htmlFor="name">
        <h4>Last name</h4>
      </label>
      <input
        className="form-control"
        defaultValue={user.lastname}
        id="lastname"
        name="lastname"
        ref={require}
        type="text"
      />

      <label htmlFor="email">
        <h4>Email</h4>
      </label>
      <input
        className="form-control"
        defaultValue={user.email}
        disabled={true}
        type="email"
      />

      <label htmlFor="role">
        <h4>Role</h4>
      </label>
      <select
        id="role"
        name="role"
        onChange={({ target: { value } }) => {
          setIsAgent(value === 'AGENT');
          setIsAdmin(value === 'ADMIN');
        }}
        ref={require}
        disabled={creatorRole === ROLE_AGENT}
      >
        <option value="COURIER" selected={user?.roles?.[0] === 'COURIER'}>
          Courier
        </option>
        <option value="ADMIN" selected={user?.roles?.[0] === 'ADMIN'}>
          Admin
        </option>
        <option value="PARTNER" selected={user?.roles?.[0] === 'PARTNER'}>
          Partner
        </option>
        <option value="AGENT" selected={user?.roles?.[0] === 'AGENT'}>
          Agent
        </option>
      </select>

      {!isAdmin && (
        <>
          <div>
            <label htmlFor="affiliation">
              <h4>Affiliation</h4>
            </label>
            <input
              className="form-control"
              defaultValue={user.affiliation}
              id="affiliation"
              name="affiliation"
              disabled={!isAgent}
              ref={register({ required: false })}
              type="text"
            />
          </div>
          <div>
            <label htmlFor="region">
              <h4>Region</h4>
            </label>
            <RegionDropdown
              id="region"
              name="region"
              blankOptionLabel={'Select Region'}
              country={country}
              defaultOptionLabel={'Select Region'}
              whitelist={{ PH: WHITELIST_REGION }}
              value={region}
              defaultValue={region}
              valueType="short"
              onChange={val => {
                setRegion(val);
              }}
              disabled={isRegionAll}
              classes={isRegionAll && 'disabled'}
            />
            {isAgent && (
              <>
                <InputCheckbox
                  isChecked={isRegionAll}
                  handleOnChange={() => {
                    setIsRegionAll(prevVal => !prevVal);
                  }}
                  checkboxLabel="Assign user to all regions"
                  disabled={true}
                />
                <label htmlFor="commisionType">
                  <h4>Commission Type</h4>
                </label>
                <select
                  id="commission_type"
                  name="commission_type"
                  ref={register({ required: true })}
                  onChange={({ target: { value } }) => {
                    setCommissionType(value);
                  }}
                >
                  <option selected={commissionType === 'flat'} value="flat">
                    Flat
                  </option>
                  <option
                    selected={commissionType === 'percentage'}
                    value="percentage"
                  >
                    Percentage
                  </option>
                </select>
                <br />
                <label htmlFor="commission">
                  <h4>Commission</h4>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="commission"
                  name="commission"
                  value={commission}
                  onChange={({ target: { value } }) => {
                    setCommission(value);
                  }}
                  ref={register({ required: true })}
                />
              </>
            )}
          </div>
          <label htmlFor="referral_code">
            <h4>Referral Code</h4>
          </label>
          <input
            className="form-control disabled"
            defaultValue={user.referral_code}
            id="referral_code"
            name="referral_code"
            ref={register({ required: false })}
            type="text"
            disabled={true}
          />
        </>
      )}

      <button type="submit" className="btn">
        Update
      </button>

      <button
        type="button"
        className="btn"
        id="deleteBtn"
        onClick={toggleConfirmationModal}
      >
        Delete
      </button>
    </form>
  );
}

export default EditUserForm;
