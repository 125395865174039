import React, { useState, createRef, useEffect } from 'react';

import { useOutsideClick } from '../../../hooks';

const CustomDropdown = props => {
  const {
    placeholder,
    options,
    onChange,
    isDisabled,
    hideAll,
    defaultValue,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue || {});

  const toggleShowOptions = () => setShowOptions(prevShow => !prevShow);

  const ref = createRef();
  useOutsideClick(ref, () => {
    setShowOptions(false);
  });

  const handleOptionClick = value => {
    setSelectedOption(value);
    onChange(value);
    toggleShowOptions();
  };

  const renderOptions = Object.keys(options || {}).map(optionKey => (
    <div
      className="custom-dropdown-option"
      key={optionKey}
      onClick={() => handleOptionClick(options[optionKey])}
    >
      {options[optionKey].label}
    </div>
  ));

  return (
    <div className="custom-dropdown-container">
      <div
        className={`custom-dropdown${isDisabled ? '-disabled' : ''}`}
        onClick={isDisabled ? null : toggleShowOptions}
      >
        {selectedOption?.label ? (
          <p className="custom-dropdown-value">{selectedOption.label}</p>
        ) : (
          <p className="custom-dropdown-placeholder">{placeholder}</p>
        )}
        <p className="custom-dropdown-icon">&#8964;</p>
      </div>

      {showOptions && (
        <div className="custom-dropdown-options" ref={ref}>
          {!hideAll && (
            <div
              className="custom-dropdown-option"
              key={'all'}
              onClick={() => handleOptionClick({})}
            >
              All
            </div>
          )}
          {renderOptions}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
