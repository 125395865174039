import React, { createContext, useEffect, useState } from 'react';

export const UsersContext = createContext();

function UsersProvider(props) {
  const [pageLength, setPageLength] = useState(1);
  const [users, setUsers] = useState({});
  const usersLimit = 25;

  useEffect(() => {
    const usersLength = Object.keys(users).length;
    setPageLength(usersLength / usersLimit);
  }, [users]);

  const setData = data => {
    let newUsers = {};
    data.forEach(user => (newUsers[user.id] = user));
    setUsers(newUsers);
  };

  return (
    <UsersContext.Provider
      value={{
        pageLength,
        setData,
        setUsers,
        users,
        usersLimit,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
}

export default UsersProvider;
