import React, { useContext, useMemo, useState } from 'react';
import SalesFilter from './SalesFilter';
import LineChart from '../../../atoms/LineChart';
import moment from 'moment';
import { objectToQueryString } from '../../../../utilities/Object.util';
import Loader from '../../../atoms/Loader';
import ApiClient from '../../../../api-client/api-client';

import { TRACKING_STATUS_OPTIONS } from '../../../../utilities/constants/shipping';

import PageHeader from '../../../atoms/PageHeader';
import { SalesContext } from '../../../templates/SalesContext';
import Export from './Export';

export default function Sales() {
  const {
    setShipmentCountPerStatus,
    setUsers,
    setChart,
    setShipmentSales,
    setFilters,
    sales,
    setProvinces,
    setRecentOrders,
  } = useContext(SalesContext);

  const { provinces, users } = sales;

  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [
    isLoadingShipmentCountPerStatus,
    setIsLoadingShipmentCountPerStatus,
  ] = useState(true);
  const [isLoadingShipmentSales, setIsLoadingShipmentSales] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [isLoadingProvinces, setIsLoadingProvinces] = useState(true);
  const [isLoadingRecentOrders, setIsLoadingRecentOrders] = useState(true);
  const [startDate, setStartDate] = useState(
    `${new Date().getFullYear()}-01-01`,
  );
  const [endDate, setEndDate] = useState(`${new Date().getFullYear()}-12-31`);

  const isLoading = !(
    !isLoadingRecentOrders &&
    !isLoadingUsers &&
    !isLoadingShipmentCountPerStatus &&
    !isLoadingShipmentSales &&
    !isLoadingChart &&
    !isLoadingProvinces
  );

  useMemo(() => {
    ApiClient.get(`/api/users/agents`)
      .then(response => {
        setUsers(response.data.payload, response.data.next_cursor);
        setIsLoadingUsers(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingUsers(false);
      });

    ApiClient.get(`/api/sales/provinces`)
      .then(response => {
        setProvinces(response.data.data);
        setIsLoadingProvinces(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingProvinces(false);
      });
  }, [setUsers, setIsLoadingUsers, setIsLoadingProvinces, setProvinces]);

  useMemo(() => {
    ApiClient.get(`/api/sales/shipment-counts-per-status`)
      .then(response => {
        setShipmentCountPerStatus(response.data.data);
        setIsLoadingShipmentCountPerStatus(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingShipmentCountPerStatus(false);
      });

    ApiClient.get(`/api/sales`)
      .then(response => {
        setShipmentSales(response.data.data);
        setIsLoadingShipmentSales(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingShipmentSales(false);
      });

    ApiClient.get(`/api/sales/chart`)
      .then(response => {
        console.log('chart', response);
        setChart(response.data.data);
        setIsLoadingChart(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingChart(false);
      });

    ApiClient.get(`/api/sales/recent-orders`)
      .then(response => {
        setRecentOrders(response.data.data);
        setIsLoadingRecentOrders(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingRecentOrders(false);
      });
  }, [setShipmentCountPerStatus, setChart, setShipmentSales, setRecentOrders]);

  const fetchingData = filters => {
    const queryString = objectToQueryString({
      ...filters,
      province: filters.province?.value || '',
      user_id: filters.agent?.value || '',
    });

    setIsLoadingShipmentCountPerStatus(true);
    setIsLoadingShipmentSales(true);
    setIsLoadingChart(true);
    setIsLoadingProvinces(true);
    setIsLoadingRecentOrders(true);

    ApiClient.get(`/api/sales/shipment-counts-per-status?${queryString}`)
      .then(response => {
        setShipmentCountPerStatus(response.data.data);
        setIsLoadingShipmentCountPerStatus(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingShipmentCountPerStatus(false);
      });

    ApiClient.get(`/api/sales?${queryString}`)
      .then(response => {
        setShipmentSales(response.data.data);
        setIsLoadingShipmentSales(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingShipmentSales(false);
      });

    ApiClient.get(`/api/sales/chart?${queryString}`)
      .then(response => {
        setChart(response.data.data);
        setIsLoadingChart(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingChart(false);
      });

    ApiClient.get(`/api/sales/provinces?${queryString}`)
      .then(response => {
        setProvinces(response.data.data);
        setIsLoadingProvinces(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingProvinces(false);
      });

    ApiClient.get(`/api/sales/recent-orders?${queryString}`)
      .then(response => {
        setRecentOrders(response.data.data);
        setIsLoadingRecentOrders(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingRecentOrders(false);
      });
  };

  const handleFilterChanges = newList => {
    const { dateRange, province, agent } = newList;

    fetchingData({
      start_date: dateRange[0],
      end_date: dateRange[1],
      province,
      agent,
    });

    setFilters({
      start_date: dateRange[0],
      end_date: dateRange[1],
      province,
      agent,
    });

    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  };

  const renderStatusBoxes = () =>
    (sales?.shipmentCountPerStatus).map(shipmentStatusCount => {
      return (
        <div
          key={shipmentStatusCount.trackingStatus}
          className="col-sm-1 col-md-1 col-lg-1 box"
        >
          <div className="box-count">{shipmentStatusCount.count}</div>
          <div className="box-status">
            {shipmentStatusCount.tracking_status}
          </div>
        </div>
      );
    });

  const renderStats = () => {
    const {
      totalPriceThisMonth,
      totalPriceToday,
      totalPriceYesterday,
      dailyTotalAverage,
      quarterlyTotalAverage,
      yearlyAverageTotal,
      totalEarnings,
    } = sales?.shipmentSales;
    return (
      <div className="col-md-3 stats-container">
        <div className="wrapper">
          <div className="stat-item stat-label">
            <div className="stat-header">This Month</div>
            <div className="stat-header">Total Earnings</div>
          </div>
          <div className="stat-item mb-3">
            <div className="stat-header-value">{totalPriceThisMonth || 0}</div>
            <div className="stat-header-value">{totalEarnings || 0}</div>
          </div>
          <div className="stat-item">
            <div className="stat-label">Today</div>
            <div className="stat-body-value">{totalPriceToday || 0}</div>
          </div>
          <div className="stat-item">
            <div className="stat-label">Yesterday</div>
            <div className="stat-body-value">{totalPriceYesterday || 0}</div>
          </div>
          <div className="stat-item">
            <div className="stat-label">Daily Average</div>
            <div className="stat-body-value">{dailyTotalAverage || 0}</div>
          </div>
          <div className="stat-item">
            <div className="stat-label">Quarterly Average</div>
            <div className="stat-body-value">{quarterlyTotalAverage || 0}</div>
          </div>
          <div className="stat-item">
            <div className="stat-label">Yearly Average</div>
            <div className="stat-body-value">{yearlyAverageTotal || 0}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderChart = () => {
    const { netSales, orders, labels, totalNetSales, totalOrders } =
      sales.chart || {};

    const chartData = {
      datasets: [
        {
          type: 'line',
          label: `Net Sales`,
          data: netSales,
          fill: false, // Set fill to false for line dataset to show only the line
          borderColor: '#A49019', // Customize the border color if desired
          tension: 0.1, // Adjust the tension of the line if desired
        },
      ],
      labels: labels,
    };

    const chartData2 = {
      datasets: [
        {
          type: 'bar',
          label: `Orders`,
          data: orders,
          backgroundColor: '#F5DD4B40', // Customize the background color if desired
          borderWidth: 0, // Customize the border width if desired
        },
      ],
      labels: labels,
    };

    return (
      <div className="col-md-6 chart-container">
        <div className="wrapper">
          <div className="chart-header-label">Net Sales</div>
          <div className="chart-header-value">{totalNetSales}</div>
          <LineChart chartData={chartData} />
          <div className="chart-header-label">Orders</div>
          <div className="chart-header-value">{totalOrders}</div>
          <LineChart chartData={chartData2} />
        </div>
      </div>
    );
  };

  const renderRecentOrders = () => {
    const { recentOrders, totalOrders } = sales?.recentOrders;

    const recentOrderItems = recentOrders.map(recenOrder => {
      const { id, price, province, trackingStatus, created } = recenOrder;
      const createdMoment = moment(created).fromNow();
      return (
        <div key={id} className="recent-order-item">
          <div>
            <span className="recent-order-item-price">{price}</span>
            <span className="recent-order-item-location">
              {' '}
              {trackingStatus} to {province}
            </span>
          </div>
          <div className="recent-order-item-date">{createdMoment}</div>
        </div>
      );
    });

    return (
      <div className="col-md-3 recenter-orders-container">
        <div className="wrapper">
          <div className="recent-order-item mb-1">
            <div className="recent-order-label">Recent Orders</div>
            <div className="recent-order-value">{totalOrders} Total Orders</div>
          </div>
          {recentOrderItems}
        </div>
      </div>
    );
  };

  return (
    <div className="sales-container row">
      <div className="col-md-10 col-md-offset-1">
        <div className="row">
          <div className="col-md-11">
            <PageHeader title="Sales" />
          </div>
          <Export
            className="export-btn col-md-1"
            startDate={startDate}
            endDate={endDate}
          >
            Export
          </Export>
        </div>
        <div className="row">
          <div className="col-md-12">
            <SalesFilter
              provinces={provinces}
              agents={users}
              trackingOptions={TRACKING_STATUS_OPTIONS}
              handleFilterChanges={handleFilterChanges}
            />
          </div>
        </div>
        {!isLoading && (
          <>
            <div className="row box-container">{renderStatusBoxes()}</div>

            <div className="stats-chart-recent-orders-container row">
              {renderStats()}
              {renderChart()}
              {renderRecentOrders()}
            </div>
          </>
        )}

        {isLoading && <Loader />}
      </div>
    </div>
  );
}
