import React, { Component, lazy, Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import UserTemplate from './components/templates/UserTemplate';
import AdminTemplate from './components/templates/AdminTemplate';
import AgentTemplate from './components/templates/AgentTemplate';
import PartnerTemplate from './components/templates/PartnerTemplate';
import ErrorBoundary from './components/organisms/ErrorBoundry.js';
import CourierTemplate from './components/templates/CourierTemplate';
import SuperTemplate from './components/templates/SuperTemplate';
import TagManager from 'react-gtm-module';

const PageNotFound = lazy(() => import('./components/pages/PageNotFound'));


class App extends Component {
  componentDidMount() {
    // const tagManagerArgs = {
    //   gtmId: 'GTM-WMCWTFSS'

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    });
  }
  // TagManager.initialize(tagManagerArgs)
  
  render() {
    return (
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
      >
        <Router>
          <ErrorBoundary>
            <Switch>
              <Route
                exact
                path={[
                  '/',
                  '/home',
                  '/login',
                  '/token/confirmation/:token',
                  '/signup',
                  '/signup-success',
                  '/forgot-password-success',
                  '/ship',
                  '/account-details',
                  '/edit-account-details',
                  '/contact',
                  '/credit-line-application',
                  '/order-history',
                  '/talk-to-us',
                  '/track',
                  '/track-display/:id',
                  '/waybill/:id/:view',
                  '/waybills/print',
                  '/prohibited-items',
                  '/terms-and-conditions',
                  '/rates/domestic',
                  '/rates/international',
                  '/reset_password/:token',
                  '/about-us',
                ]}
              >
                <UserTemplate />
              </Route>
              <Route exact path={['/sa/*']}>
                <SuperTemplate />
              </Route>
              <Route exact path={['/admin/*']}>
                <AdminTemplate />
              </Route>
              <Route exact path={['/partner/*']}>
                <PartnerTemplate />
              </Route>
              <Route exact path={['/agent/*']}>
                <AgentTemplate />
              </Route>
              <Route exact path={['/courier', '/courier/*']}>
                <CourierTemplate />
              </Route>

              <Suspense
                fallback={<div style={{ marginTop: '100px' }}>Loading...</div>}
              >
                <Route exact path={['/404', '*']} component={PageNotFound} />
              </Suspense>
            </Switch>
          </ErrorBoundary>
        </Router>
      </SnackbarProvider>
    );
  }
}

export default App;
