import React, { Fragment, Suspense, lazy } from 'react'
import { Route } from "react-router-dom"

import UserProvider from "./UserContext"
import SuperNavbar from "../organisms/SuperNavbar"
import UsersProvider from "./UsersContext"
import ShipmentsProvider from "./ShipmentsContext"
import SalesProvider from "./SalesContext"
import AuthTemplate from "./AuthTemplate"
import { ROLE_SUPER } from "../../utilities/constants"

const Users = lazy(() => import('../pages/super/users/Users'));
const AddUsers = lazy(() => import('../pages/super/AddUsers'));
const EditUsers = lazy(() => import('../pages/super/EditUsers'));
const Shipments = lazy(() => import('../pages/super/shipments/Shipments'));
const ProofOfDelivery = lazy(() => import('../pages/super/ProofOfDelivery'));
const Sales = lazy(() => import('../pages/super/sales'));

export default function SuperTemplate() {
  return (
    <Fragment>
      <UserProvider>
				<SuperNavbar/>
				<div id="top" className="container-fluid parallax text-center container-has-secondary-navbar">
					<Suspense
            fallback={<div style={{ marginTop: '100px' }}>Loading...</div>}
          >
						<UsersProvider>
							<Route
								exact
								path="/sa/users"
								component={AuthTemplate(Users, ROLE_SUPER)}
							/>
							<Route
								exact
								path="/sa/users/add"
								component={AuthTemplate(AddUsers, ROLE_SUPER)}
							/>
							<Route
								exact
								path="/sa/users/edit/:id"
								component={AuthTemplate(EditUsers, ROLE_SUPER)}
							/>
						</UsersProvider>
						<ShipmentsProvider>
							<Route
								exact
								path="/sa/shipments"
								component={AuthTemplate(Shipments, ROLE_SUPER)}
							/>
							<Route
								exact
								path="sa/shipments/:id/proof-of-delivery"
								component={AuthTemplate(ProofOfDelivery, ROLE_SUPER)}
							/>
						</ShipmentsProvider>
						<SalesProvider>
							<Route
								exact
								path="/sa/sales"
                component={AuthTemplate(Sales, ROLE_SUPER)}
              />
						</SalesProvider>
					</Suspense>
				</div>
			</UserProvider>
    </Fragment>
  )
}
