import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../templates/UserContext';

const AuthTemplate = (WrappedComponent, allowedRole) => {
  class WithAuthorization extends Component {
    static contextType = UserContext;
    state = {
      isLoading: true,
      isAllowed: false,
    };

    componentDidMount = () => {
      // Check if currrent user is logged in.
      if (localStorage.user) {
        console.log('Has user in localStorage');
        console.log(JSON.parse(localStorage.user));
      }

      const user = JSON.parse(localStorage.getItem('userDetails'));
      this.context.saveUserDetails(user);

      this.setState({ isLoading: false });
      if (this.props.location.pathname === '/courier/change_status') {
        this.setState({ isAllowed: true });
      } else if (user?.roles?.includes(allowedRole)) {
        this.setState({ isAllowed: true });
      }
    };

    render() {
      const { isLoading } = this.state;
      if (isLoading) {
        return <div style={{ marginTop: '100px' }}>Loading...</div>;
      }

      if (this.state.isAllowed) {
        return <WrappedComponent {...this.props} />;
      }

      return <Redirect to="/" {...this.props} />;
    }
  }
  return WithAuthorization;
};

export default AuthTemplate;
