import React from "react";
import { Line } from 'react-chartjs-2';

function LineChart({ chartData }) {
  return (
    <div className="chart-container">
      <Line
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom', // Set the position to 'left'
              align: 'start', // Align the legend to the start (top) side
            }
          }
        }}
      />
    </div>
  );
}

export default LineChart;