import React, { Component, Suspense, lazy, Fragment } from 'react';
import { Route } from 'react-router-dom';

import PartnerNavbar from '../organisms/PartnerNavbar';
import AuthTemplate from './AuthTemplate';
import UserProvider from './UserContext';
import ShipmentsProvider from './ShipmentsContext';
import { ROLE_PARTNER } from '../../utilities/constants';

const Shipments = lazy(() => import('../pages/partner/shipment'));

class PartnerTemplate extends Component {
  render() {
    return (
      <Fragment>
        <UserProvider>
          <PartnerNavbar />
          <div
            className="container-fluid parallax text-center container-has-secondary-navbar"
            id="top"
          >
            <Suspense
              fallback={<div style={{ marginTop: '100px' }}>Loading...</div>}
            >
              <ShipmentsProvider>
                <Route
                  exact
                  path="/partner/shipments"
                  component={AuthTemplate(Shipments, ROLE_PARTNER)}
                />
              </ShipmentsProvider>
            </Suspense>
          </div>
        </UserProvider>
      </Fragment>
    );
  }
}

export default PartnerTemplate;
