import React from 'react';
import '../../assets/css/components/DeleteConfirmationModal.css'

function DeleteConfirmationModal(props) {
	const { handleDelete, toggleConfirmationModal } = props;

  return (
		<div className="delete-modal">
			<div
				className="delete-modal-overlay"
				onClick={toggleConfirmationModal}	
			/>
			<div className="delete-modal-component">
				<div className="delete-modal-message">
					<i
          	className="fa fa-times-circle-o"
          	style={{ fontSize: '100px', color: '#F15E5E' }}
        	></i>

					<h3>Delete Confirmation</h3>
					<p>Are you sure you want to delete this user?</p>
				</div>
				<div className="delete-modal-buttons">
					<button type="button" className="delete-modal-buttons-cancelBtn" onClick={toggleConfirmationModal}>
						Cancel
					</button>
					<button type="button" className="delete-modal-buttons-deleteBtn" onClick={handleDelete}>
						Yes, delete
					</button>
				</div>
			</div>
		</div>
  )
}

export default DeleteConfirmationModal;