import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { ROLE_COURIER } from '../../utilities/constants';
import CourierNavbar from '../organisms/CourierNavbar';
import CourierStatusChangeSuccess from '../pages/CourierStatusChangeSuccess';
import CourierChangeStatus from '../pages/CourierChangeStatus';
import ShipmentsProvider from './ShipmentsContext';
import AuthTemplate from './AuthTemplate';
import UserProvider from './UserContext';

const CourierStatus = lazy(() => import('../pages/CourierStatus'));
const CourierHome = lazy(() => import('../pages/CourierHome'));
const CourierShipments = lazy(() => import('../pages/courier/shipment'));

export default function CourierTemplate(props) {
  return (
    <UserProvider>
      <CourierNavbar />
        <Suspense
          fallback={
            <div className="text-center" style={{ marginTop: '300px' }} id="top">
              Loading...
            </div>
          }
        >
          <Route
            exact
            path="/courier"
            component={AuthTemplate(CourierHome, ROLE_COURIER)}
          />
          <Route
            exact
            path="/courier/scanner"
            component={AuthTemplate(CourierStatus, ROLE_COURIER)}
          />
          <ShipmentsProvider>
            <Route
              exact
              path="/courier/shipments"
              component={AuthTemplate(CourierShipments, ROLE_COURIER)}
            />
          </ShipmentsProvider>
          <Route
            exact
            path="/courier/scanner/success"
            component={AuthTemplate(CourierStatusChangeSuccess, ROLE_COURIER)}
          />
          <Route
            exact
            path="/courier/change_status"
            component={AuthTemplate(CourierChangeStatus, ROLE_COURIER)}
          />
        </Suspense>
    </UserProvider>
  );
}
