import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { RegionDropdown } from 'react-country-region-selector';
import { WHITELIST_REGION } from '../../utilities/constants/rates';
import { COUNTRY_DOMESTIC } from '../../utilities/constants/shipping';
import {
  ROLE_ADMIN,
  ROLE_AGENT,
  ROLE_PARTNER,
  ROLE_COURIER,
} from '../../utilities/constants';
import InputCheckbox from '../atoms/InputCheckbox';
import { useLocation } from 'react-router-dom';

function SignupForm(props) {
  const { register, handleSubmit, errors, watch } = useForm();
  const [isAgent, setIsAgent] = useState(false);
  const [isCourier, setIsCourier] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isRegionAll, setIsRegionAll] = useState(false);
  const { onSubmit, buttonSubmitText, setRegion, region, creatorRole } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get('ref');
  const user = JSON.parse(localStorage.getItem('userDetails'));
  const password = watch('password');
  const [commisionType, setCommisionType] = useState('flat');
  const [commision, setCommision] = useState(0);

  useEffect(() => {
    if (commisionType === 'percentage') {
      setCommision(Math.min(Math.max(commision, 0), 100));
    }
    if (commisionType === 'flat') {
      setCommision(Math.max(commision, 0));
    }
    if (commision === '') {
      setCommision('');
    }
  }, [commision, commisionType]);

  const countryKey = 'ship_country';

  const country = localStorage.getItem(countryKey) || COUNTRY_DOMESTIC;

  const handleRegionChange = value => {
    setRegion(value);
  };

  useEffect(() => {
    if (isAgent) {
      setIsRegionAll(true);
    } else {
      setIsRegionAll(false);
    }
  }, [isAgent]);

  useEffect(() => {
    if (user && user.roles) {
      if (user.roles.includes('SUPER') || user.roles.includes('ADMIN')) {
        if (isRegionAll && setRegion) {
          setRegion('ALL');
        } else if (!isRegionAll && setRegion) {
          setRegion('');
        }
      }
    }
  }, [isRegionAll]);

  const renderRoleSelect = {
    [ROLE_ADMIN]: (
      <>
        <label htmlFor="role">
          <h4>Role</h4>
        </label>
        <select
          id="role"
          name="role"
          onChange={({ target: { value } }) => {
            setIsAgent(value === ROLE_AGENT);
            setIsCourier(value === ROLE_COURIER);
            setIsPartner(value === ROLE_PARTNER);
          }}
          ref={register({
            required: true,
          })}
        >
          <option>-</option>
          <option value={ROLE_COURIER}>Courier</option>
          <option value={ROLE_ADMIN}>Admin</option>
          <option value={ROLE_PARTNER}>Partner</option>
          <option value={ROLE_AGENT}>Agent</option>
        </select>
        <br />
      </>
    ),
    [ROLE_AGENT]: (
      <>
        <label htmlFor="role">
          <h4>Role</h4>
        </label>
        <select
          id="role"
          name="role"
          ref={register({
            required: true,
          })}
          defaultValue={ROLE_PARTNER}
          disabled
        >
          <option value={ROLE_PARTNER}>Partner</option>
        </select>
        <br />
      </>
    ),
  };

  return (
    <form className="sign-form" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="name">
        <h4>First name</h4>
      </label>
      <input
        type="text"
        className="form-control"
        id="firstname"
        name="firstname"
        ref={register({ required: true })}
      />

      <label htmlFor="name">
        <h4>Last name</h4>
      </label>
      <input
        type="text"
        className="form-control"
        id="lastname"
        name="lastname"
        ref={register({ required: true })}
      />

      <label htmlFor="email">
        <h4>Email</h4>
      </label>
      <input
        type="email"
        className="form-control"
        id="email"
        name="email"
        autoComplete="email"
        ref={register({ required: true })}
      />

      <label htmlFor="password">
        <h4>Password</h4>
      </label>
      <input
        type="password"
        className="form-control"
        id="password"
        name="password"
        autoComplete="current-password"
        ref={register({ required: true })}
      />

      <label htmlFor="confirm-password">
        <h4>Re-enter Password</h4>
      </label>
      <input
        type="password"
        className="form-control"
        id="confirm-password"
        name="confirm_password"
        autoComplete="new-password"
        ref={register({
          required: true,
          validate: value => value === password,
        })}
      />
      {errors.confirm_password && (
        <p style={{ fontSize: '0.8em', color: 'red' }}>Passwords don't match</p>
      )}
      {props.isAdmin ? (
        <>
          <input
            type="hidden"
            className="form-control"
            id="referral_code"
            name="referral_code"
            ref={register({ required: false })}
          />
        </>
      ) : (
        <>
          <label htmlFor="referral_code">
            <h4>Referral Code{!ref && ' (Optional)'}</h4>
          </label>
          <input
            type="text"
            className="form-control"
            id="referral_code"
            name="referral_code"
            ref={register({ required: false })}
            value={(user && user.referral_code) || ref?.toUpperCase()}
            disabled={creatorRole === ROLE_AGENT || !!ref}
          />
        </>
      )}
      {creatorRole ? renderRoleSelect[creatorRole] : null}
      {[isAgent, isCourier, isPartner].some(i => i === true) && (
        <>
          <label htmlFor="affiliation">
            <h4>Affiliation</h4>
          </label>
          <input
            type="text"
            className="form-control"
            id="affiliation"
            name="affiliation"
            ref={register({ required: false })}
          />
          <>
            <label htmlFor="region">
              <h4>Region</h4>
            </label>
            <RegionDropdown
              id="region"
              name="region"
              blankOptionLabel="Select Region"
              country={country}
              defaultOptionLabel="Select Region"
              whitelist={{ PH: WHITELIST_REGION }}
              value={region}
              valueType="short"
              onChange={handleRegionChange}
              disabled={isRegionAll}
              classes={isRegionAll && 'disabled'}
            />
          </>
          {isAgent && (
            <>
              <InputCheckbox
                isChecked={isRegionAll}
                handleOnChange={() => {
                  setIsRegionAll(prevVal => !prevVal);
                }}
                checkboxLabel="Assign user to all regions"
                disabled={true}
              />
              <label htmlFor="commisionType">
                <h4>Commission Type</h4>
              </label>
              <select
                id="commission_type"
                name="commission_type"
                ref={register({ required: true })}
                onChange={({ target: { value } }) => {
                  setCommisionType(value);
                }}
              >
                <option selected={commisionType === 'flat'} value="flat">
                  Flat
                </option>
                <option
                  selected={commisionType === 'percentage'}
                  value="percentage"
                >
                  Percentage
                </option>
              </select>
              <br />
              <label htmlFor="commission">
                <h4>Commission</h4>
              </label>
              <input
                type="number"
                className="form-control"
                id="commission"
                name="commission"
                value={commision}
                onChange={({ target: { value } }) => {
                  setCommision(value);
                }}
                ref={register({ required: true })}
              />
            </>
          )}
        </>
      )}
      <button type="submit" className="btn">
        {buttonSubmitText || 'Create your Flying Tigers Account'}
      </button>
    </form>
  );
}

export default SignupForm;
