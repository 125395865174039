export function clearData() {
  //removes all ship details from the local storage
  const additionalChargeKey = 'ship_additionalCharge';
  const chargePaymentOptionKey = 'ship_chargePaymentOption';
  const companyNameKey = 'ship_companyName';
  const consigneeCompanyNameKey = 'ship_consigneeCompanyName';
  const contactNumberKey = 'ship_contactNumber';
  const consigneeContactNumberKey = 'ship_consigneeContactNumber';
  const consigneeCountryKey = 'ship_consigneeCountry';
  const consigneeDistrictBarangayKey = 'ship_consigneeDistrictBarangay';
  const consigneeEmailKey = 'ship_consigneeEmail';
  const consigneeNameKey = 'ship_consigneeName';
  const consigneeProvinceKey = 'ship_consigneeProvince';
  const consigneeStreetAddressKey = 'ship_consigneeStreetAddress';
  const consigneeTownCityKey = 'ship_consigneeTownCity';
  const consigneeZipCodeKey = 'ship_consigneeZipCode';
  const countryKey = 'ship_country';
  const districtBarangayKey = 'ship_districtBarangay';
  const emailKey = 'ship_email';
  const isShippingForSelfKey = 'ship_isShippingForSelf';
  const itemPriceKey = 'ship_itemPrice';
  const nameKey = 'ship_name';
  const otherRemarksInstructionsKey = 'ship_otherRemarksInstructions';
  const orderNumberKey = 'ship_orderNumber';
  const packageHeightKey = 'ship_packageHeight';
  const packageLengthKey = 'ship_packageLength';
  const packageSizeKey = 'ship_packageSize';
  const packageWeightKey = 'ship_packageWeight';
  const packageWidthKey = 'ship_packageWidth';
  const packageTypesListIndexKey = 'ship_packageTypesListIndex';
  const modeOfTransportationKey = 'ship_modeOfTransportation';
  const provinceKey = 'ship_province';
  const remarksInstructionsKey = 'ship_remarksInstructions';
  const serviceTypeKey = 'ship_serviceType';
  const shippingChargeKey = 'ship_shippingCharge';
  const streetAddressKey = 'ship_streetAddress';
  const scopeKey = 'ship_scope';
  const stepIndexKey = 'ship_stepIndex';
  const totalPriceKey = 'ship_totalPrice';
  const townCityKey = 'ship_townCity';
  const trackingNumberKey = 'ship_trackingNumber';
  const volumetricWeightKey = 'ship_volumetricWeight';
  const warehousePortCodeKey = 'ship_warehousePortCode';
  const zipCodeKey = 'ship_zipCode';
  const packageValueKey = 'ship_packageValue';
  const selectedPackagesKey = 'ship_selectedPackages';
  const pricesBreakdown = 'ship_pricesBreakdown';

  const shipKey = [
    additionalChargeKey,
    chargePaymentOptionKey,
    companyNameKey,
    consigneeCompanyNameKey,
    contactNumberKey,
    consigneeContactNumberKey,
    consigneeCountryKey,
    consigneeDistrictBarangayKey,
    consigneeEmailKey,
    consigneeNameKey,
    consigneeProvinceKey,
    consigneeStreetAddressKey,
    consigneeTownCityKey,
    consigneeZipCodeKey,
    countryKey,
    districtBarangayKey,
    emailKey,
    isShippingForSelfKey,
    itemPriceKey,
    nameKey,
    otherRemarksInstructionsKey,
    orderNumberKey,
    packageHeightKey,
    packageLengthKey,
    packageWeightKey,
    packageWidthKey,
    packageSizeKey,
    packageTypesListIndexKey,
    modeOfTransportationKey,
    provinceKey,
    remarksInstructionsKey,
    serviceTypeKey,
    shippingChargeKey,
    streetAddressKey,
    scopeKey,
    stepIndexKey,
    totalPriceKey,
    townCityKey,
    trackingNumberKey,
    volumetricWeightKey,
    warehousePortCodeKey,
    zipCodeKey,
    packageValueKey,
    selectedPackagesKey,
    pricesBreakdown,
  ];

  shipKey.map(dataKey => localStorage.removeItem(dataKey));
}
