import React, { Component } from 'react';
import { Link as NavLink } from 'react-router-dom';

import logo from '../../assets/images/logo_FTE_yellow_new.png'; 

import RoutedUserMenu from '../molecules/UserMenu';
import NavbarRight from '../molecules/NavbarRight';

export default class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-inverse navbar-fixed-top">
        <div className="container-fluid">
          <div className="row">
            <div className="">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#mainNavbarMobile"
                style={{ marginTop: '1.6em' }}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className="" style={{ float: 'right', marginTop: '1em' }}>
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#rightNavbarMobile"
              >
                <i
                  className="fa fa-user-circle-o"
                  style={{ fontSize: '24px', color: '#e6c702' }}
                ></i>
              </button>
            </div>
            <div className="navbar-header">
              <NavLink to="/">
                <img src={logo} alt="Flying Tigers Express" id="header-logo" />
              </NavLink>
            </div>
            {/* <!-- Main Navigation Items --> */}
            <div className="">
               <div
                  className="navbar-header collapse"
                  id="mainNavbarMobile"
               >
                  <ul className="nav" id="nav">
                     <li data-toggle="collapse" data-target="#mainNavbarMobile">
                        <NavLink to="/track">
                           <span>Track</span>
                        </NavLink>
                     </li>
                     <li data-toggle="collapse" data-target="#mainNavbarMobile">
                        <NavLink to="/ship">
                           <span>Ship</span>
                        </NavLink>
                     </li>
                     <li data-toggle="collapse" data-target="#mainNavbarMobile">
                        <NavLink to="/rates/domestic">
                           <span>Rates</span>
                        </NavLink>
                     </li>
                     <li data-toggle="collapse" data-target="#mainNavbarMobile">
                        <NavLink to="/about-us">
                           <span>About Us</span>
                        </NavLink>
                     </li>
                     <li data-toggle="collapse" data-target="#mainNavbarMobile">
                        <NavLink to="/talk-to-us">
                           <span>Talk To Us</span>
                        </NavLink>
                     </li>
                     <li data-toggle="collapse" data-target="#mainNavbarMobile">
                     <NavLink to="/prohibited-items">
                        <span>Prohibited Items</span>
                     </NavLink>
                     </li>
                     <li data-toggle="collapse" data-target="#mainNavbarMobile">
                        <NavLink to="/terms-and-conditions">
                           <span>Terms & Conditions</span>
                        </NavLink>
                     </li>
                  </ul>
               </div>
               <div
                  className="navbar-header"
                  id="mainNavbar"
               >
                  <ul className="nav" id="nav">
                     <li>
                        <NavLink to="/track">
                           <span>Track</span>
                        </NavLink>
                     </li>
                     <li>
                        <NavLink to="/ship">
                           <span>Ship</span>
                        </NavLink>
                     </li>
                     <li>
                        <NavLink to="/rates/domestic">
                           <span>Rates</span>
                        </NavLink>
                     </li>
                     <li>
                        <NavLink to="/about-us">
                           <span>About Us</span>
                        </NavLink>
                     </li>
                     <li>
                        <NavLink to="/talk-to-us">
                           <span>Talk To Us</span>
                        </NavLink>
                     </li>
                     <li>
                     <NavLink to="/prohibited-items">
                        <span>Prohibited Items</span>
                     </NavLink>
                     </li>
                     <li>
                        <NavLink to="/terms-and-conditions">
                           <span>Terms & Conditions</span>
                        </NavLink>
                     </li>
                  </ul>
               </div>
               {/* <!-- Right Navbar Items --> */}
               <NavbarRight />
            </div>
            <RoutedUserMenu />
          </div>
        </div>
      </nav>
    );
  }
}
