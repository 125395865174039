import { createHash } from 'crypto';
import axios from "axios";

export function generateRandomCharacters(length) {
  const timestamp = Date.now().toString();
  const hash = createHash('sha256')
    .update(timestamp)
    .digest('hex');

  const alphanumeric = hash.replace(/[^a-z0-9]/gi, '').toUpperCase();
  const code = alphanumeric.slice(0, length);

  return code;
}

export function generateReferralCode(handleUpdate){
  let code = generate();
  checkReferralCode();

  function generate(){
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    let code = '';
    for (let i = 0; i < 6; i++) {
      code += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    for (let i = 0; i < 3; i++) {
      code += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    return code;
  }

  function checkReferralCode(){
    axios.get(`/api/check_referral_code/${code}`)
      .then(response => {
        if(response.data.exists){
          code = generate();
          checkReferralCode();
        } else {
          const updatedReferralCode = code;
          handleUpdate(updatedReferralCode);
        }
      })
      .catch(error => {
        alert(`There is an error upon checking if the referral code exists. Please try again.
        \nError message: ${error}`);
      })
  }
}