import React, { Component, Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

import {
  ROLE_ADMIN,
  ROLE_AGENT,
  ROLE_COURIER,
  ROLE_PARTNER,
  ROLE_SUPER,
} from '../../utilities/constants.js';
import UserProvider from './UserContext';

import Navbar from '../organisms/Navbar.js';
import AdminNavbar from '../organisms/AdminNavbar.js';
import AgentNavbar from '../organisms/AgentNavbar.js';
import CourierNavbar from '../organisms/CourierNavbar.js';
import PartnerNavbar from '../organisms/PartnerNavbar.js';
import SuperNavbar from '../organisms/SuperNavbar.js';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min.js';

const AccountDetails = lazy(() => import('../pages/AccountDetails'));
const EditAccountDetails = lazy(() => import('../pages/EditAccountDetails'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const CreditLineApplication = lazy(() =>
  import('../pages/CreditLineApplication'),
);
const Home = lazy(() => import('../pages/Home'));
const ConfirmEmail = lazy(() => import('../pages/ConfirmEmail'));
const Landing = lazy(() => import('../pages/Landing'));
const Login = lazy(() => import('../pages/Login'));
const Rates = lazy(() => import('../pages/Rates'));
const Ship = lazy(() => import('../pages/Ship'));
const ShippingHistory = lazy(() => import('../pages/ShippingHistory'));
const SignUp = lazy(() => import('../pages/SignUp'));
const SignupSuccess = lazy(() => import('../pages/SignupSuccess'));
const ForgotPasswordSuccess = lazy(() =>
  import('../pages/ForgotPasswordSuccess'),
);
const TalkToUs = lazy(() => import('../pages/TalkToUs'));
const Track = lazy(() => import('../pages/Track'));
const TrackDisplay = lazy(() => import('../pages/TrackDisplay'));
const Waybill = lazy(() => import('../pages/Waybill'));
const ProhibitedItems = lazy(() => import('../pages/ProhibitedItems'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const Waybills = lazy(() => import('../pages/Waybills'));
const AboutUs = lazy(() => import('../pages/AboutUs'));

export default class UserTemplate extends Component {
  state = {
    isSuperAdmin: false,
    isAdmin: false,
    isAgent: false,
    isCourier: false,
    isPartner: false,
    hasUser: false,
  };

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('userDetails'));

    this.setState({ hasUser: !!user });

    if (user?.roles.includes(ROLE_SUPER)) {
      this.setState({ isSuperAdmin: true });
    }

    if (user?.roles.includes(ROLE_ADMIN)) {
      this.setState({ isAdmin: true });
    }

    if (user?.roles.includes(ROLE_AGENT)) {
      this.setState({ isAgent: true });
    }

    if (user?.roles.includes(ROLE_COURIER)) {
      this.setState({ isCourier: true });
    }

    if (user?.roles.includes(ROLE_PARTNER)) {
      this.setState({ isPartner: true });
    }
  };

  componentWillMount = () => {
    this.setState({ isAdmin: false });
  };

  render() {
    const navbarDisplay = () => {
      if (this.state.hasUser) {
        if (this.state.isSuperAdmin) return <SuperNavbar />;
        else if (this.state.isAdmin) return <AdminNavbar />;
        else if (this.state.isAgent) return <AgentNavbar />;
        else if (this.state.isCourier) return <CourierNavbar />;
        else if (this.state.isPartner) return <PartnerNavbar />;
      }

      return <Navbar />;
    };

    return (
      <UserProvider>
        {navbarDisplay()}

        <Suspense
          fallback={
            <div
              className="text-center"
              style={{ marginTop: '300px' }}
              id="top"
            >
              Loading...
            </div>
          }
        >
          <Route exact path="/" component={Home} />
          <Route exact path="/home" render={() => <Redirect to="/" />} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/signup" component={SignUp} />
          <Route
            exact
            path="/token/confirmation/:token"
            component={ConfirmEmail}
          />
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/reset_password/:token"
            component={ForgotPassword}
          />
          <Route exact path="/signup-success" component={SignupSuccess} />
          <Route
            exact
            path="/forgot-password-success"
            component={ForgotPasswordSuccess}
          />
          <Route exact path="/ship" component={Ship} />
          <Route exact path="/account-details" component={AccountDetails} />
          <Route
            exact
            path="/edit-account-details"
            component={EditAccountDetails}
          />
          <Route exact path="/contact" component={ContactUs} />
          <Route
            exact
            path="/credit-line-application"
            component={CreditLineApplication}
          />
          <Route exact path="/order-history" component={ShippingHistory} />
          <Route exact path="/talk-to-us" component={TalkToUs} />
          <Route exact path="/track" component={Track} />
          <Route exact path="/track-display/:id" component={TrackDisplay} />
          <Route exact path="/waybill/:id/:view" component={Waybill} />
          <Route exact path="/waybills/print" component={Waybills} />
          <Route exact path="/prohibited-items" component={ProhibitedItems} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/rates/domestic" component={Rates} />
          <Route exact path="/about-us" component={AboutUs} />
        </Suspense>
      </UserProvider>
    );
  }
}
