import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo_FTE_yellow_new.png';

import RoutedUserMenu from '../molecules/UserMenu';
import NavbarRight from '../molecules/NavbarRight';
import AdminSecondaryNavbar from "./AdminSecondaryNavbar";

export default class AdminNavbar extends Component {
  render() {
    return (
    <>
      <nav className="navbar navbar-inverse navbar-fixed-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#mainNavbarMobile"
                style={{ marginTop: '1.6em' }}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className="col" style={{ float: 'right', marginTop: '1em' }}>
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#rightNavbarMobile"
              >
                <i
                  className="fa fa-user-circle-o"
                  style={{ fontSize: '24px', color: '#e6c702' }}
                ></i>
              </button>
            </div>
            <div className="navbar-header">
              <Link to="/">
                <img src={logo} alt="Flying Tigers Express" id="header-logo" />
              </Link>
            </div>
            {/* <!-- Main Navigation Items --> */}
            <div className="col">
              <div
                className="navbar-header collapse"
                id="mainNavbarMobile"
              >
                <ul className="nav" id="nav">
                  <li data-toggle="collapse" data-target="#mainNavbarMobile">
                    <Link to="/track">
                      <span>Track</span>
                    </Link>
                  </li>
                  <li data-toggle="collapse" data-target="#mainNavbarMobile">
                    <Link to="/ship">
                      <span>Ship</span>
                    </Link>
                  </li>
                  <li data-toggle="collapse" data-target="#mainNavbarMobile">
                    <Link to="/rates/domestic">
                      <span>Rates</span>
                    </Link>
                  </li>
                  <li data-toggle="collapse" data-target="#mainNavbarMobile">
                    <Link to="/about-us">
                      <span>About Us</span>
                    </Link>
                  </li>
                  <li data-toggle="collapse" data-target="#mainNavbarMobile">
                    <Link to="/talk-to-us">
                      <span>Talk To Us</span>
                    </Link>
                  </li>
                  <li data-toggle="collapse" data-target="#mainNavbarMobile">
                    <Link to="/prohibited-items">
                      <span>Prohibited Items</span>
                    </Link>
                  </li>
                  <li data-toggle="collapse" data-target="#mainNavbarMobile">
                    <Link to="/terms-and-conditions">
                      <span>Terms & Conditions</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="navbar-header"
                id="mainNavbar"
              >
                <ul className="nav" id="nav">
                  <li>
                    <Link to="/track">
                      <span>Track</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/ship">
                      <span>Ship</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/rates/domestic">
                      <span>Rates</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us">
                      <span>About Us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/talk-to-us">
                      <span>Talk To Us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/prohibited-items">
                      <span>Prohibited Items</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">
                      <span>Terms & Conditions</span>
                    </Link>
                  </li>
                </ul>
               </div>
               {/* <!-- Right Navbar Items --> */}
               <NavbarRight />
            </div>
            <RoutedUserMenu />
          </div>
        </div>
      </nav>
      <AdminSecondaryNavbar />
    </>
    );
  }
}
