export const COUNTRY_DOMESTIC = 'Philippines';

export const CHECKLIST_CATEGORY_LIST = [
  'Package Details',
  'Merchandise Payment Details',
  'Shipper Details',
  'Consignee Details',
  'Freight Charges Payment Details',
];

export const SHIPPING_STEP_SHIPPING_SCOPE = 'shipping_scope';
export const SHIPPING_STEP_SERVICE_TYPE = 'service_type';
export const SHIPPING_STEP_SOURCE_ADDRESS = 'source_address';
export const SHIPPING_STEP_SOURCE_PERSONAL_DETAILS = 'source_personal_details';
export const SHIPPING_STEP_PACKAGE_TYPE = 'package_type';
export const SHIPPING_STEP_PACKAGE_WEIGHT_VOLUME = 'package_weight_volume';
export const SHIPPING_STEP_DESTINATION_ADDRESS = 'destination_address';
export const SHIPPING_STEP_DESTINATION_PERSONAL_DETAILS =
  'destination_personal_details';
export const SHIPPING_STEP_FREIGHT_CHARGE_PAYMENT = 'freight_charge_payment';
export const SHIPPING_STEP_PAYMENT_DETAILS = 'payment_details';
export const SHIPPING_STEP_PACKAGE_VALUE = 'package_value';
export const SHIPPING_STEP_REMARKS_INSTRUCTIONS = 'remarks_instructions';
export const SHIPPING_STEP_CONFIRMATION = 'details_confirm';
export const SHIPPING_STEP_SHIPPING_COST = 'shipping_cost';
export const SHIPPING_STEPS_LIST = [
  SHIPPING_STEP_SERVICE_TYPE,
  SHIPPING_STEP_PACKAGE_TYPE,
  SHIPPING_STEP_PACKAGE_VALUE,
  SHIPPING_STEP_SOURCE_ADDRESS,
  SHIPPING_STEP_SOURCE_PERSONAL_DETAILS,
  SHIPPING_STEP_DESTINATION_ADDRESS,
  SHIPPING_STEP_DESTINATION_PERSONAL_DETAILS,
  SHIPPING_STEP_REMARKS_INSTRUCTIONS,
  SHIPPING_STEP_FREIGHT_CHARGE_PAYMENT,
  SHIPPING_STEP_CONFIRMATION,
  SHIPPING_STEP_SHIPPING_COST,
];
export const SHIPPING_STEPS_VERBOSE = {
  [SHIPPING_STEP_SHIPPING_SCOPE]: 'I am shipping from',
  [SHIPPING_STEP_SERVICE_TYPE]: 'Select type of service',
  [SHIPPING_STEP_SOURCE_ADDRESS]: 'I am shipping from',
  [SHIPPING_STEP_SOURCE_PERSONAL_DETAILS]: 'Your details',
  [SHIPPING_STEP_PACKAGE_TYPE]: 'I am shipping a',
  [SHIPPING_STEP_DESTINATION_ADDRESS]: 'I am shipping to',
  [SHIPPING_STEP_DESTINATION_PERSONAL_DETAILS]: 'Consignee details',
  [SHIPPING_STEP_PACKAGE_VALUE]: 'Package Value for Insurance',
  [SHIPPING_STEP_FREIGHT_CHARGE_PAYMENT]: 'Freight charge payment',
  [SHIPPING_STEP_PAYMENT_DETAILS]: 'Merchandise payment details',
  [SHIPPING_STEP_REMARKS_INSTRUCTIONS]: 'Any handling remarks or instructions?',
  [SHIPPING_STEP_CONFIRMATION]:
    'Please read Terms and Conditions and Items Considered as Prohibited. By clicking “Next”, you agree to the terms and conditions and that there are no prohibited items in you package. Please ensure that that all packages are not sealed and subject for inspection by FTE representative. Final size and weight are subject for validation by FTE representative. You will be informed immediately of the additional charges or refund should there be differences in cost pregenerated.',
  [SHIPPING_STEP_SHIPPING_COST]: 'Shipment Details & Cost',
};
export const SHIPPING_STEPS = Object.keys(SHIPPING_STEPS_VERBOSE).map(
  shippingSteps => {
    return {
      value: shippingSteps,
      verbose: SHIPPING_STEPS_VERBOSE[shippingSteps],
    };
  },
);

export const SCOPE_TYPE_DOMESTIC = 'domestic';
export const SCOPE_TYPE_INTERNATIONAL = 'international';
export const SCOPE_TYPE_MNL = 'Metro Manila';
export const SCOPE_TYPE_CEB = 'Cebu';
export const SCOPE_TYPES_VERBOSE = {
  [SCOPE_TYPE_MNL]: 'Manila',
  [SCOPE_TYPE_CEB]: 'Cebu',
};
export const SCOPE_TYPES = Object.keys(SCOPE_TYPES_VERBOSE).map(scopeType => {
  return { value: scopeType, verbose: SCOPE_TYPES_VERBOSE[scopeType] };
});

export const SERVICE_TYPE_SAME_DAY_DELIVERY = 'same_day_delivery';
export const SERVICE_TYPE_NEXT_DAY_DELIVERY = 'next_day_delivery';
export const SERVICE_TYPE_DEFERRED_EXPRESS = 'deferred_express';
export const SERVICE_TYPE_DOCUMENTS = 'documents';
export const SERVICE_TYPE_OTHERS = 'others';
export const SERVICE_TYPE_CARGO = 'cargo'; // Not included in list. For classification purposes
export const SERVICE_TYPES_VERBOSE = {
  [SERVICE_TYPE_NEXT_DAY_DELIVERY]: 'Next day delivery',
  [SERVICE_TYPE_SAME_DAY_DELIVERY]: 'Same day delivery',
};

export const SERVICE_TYPES = Object.keys(SERVICE_TYPES_VERBOSE).map(
  serviceType => {
    return { value: serviceType, verbose: SERVICE_TYPES_VERBOSE[serviceType] };
  },
);

export const MODE_OF_TRANSPORTATION_AIR = 'air';
export const MODE_OF_TRANSPORTATION_SEA = 'sea';
export const MODE_OF_TRANSPORTATION_TRUCKING = 'trucking';
export const MODES_OF_TRANSPORTATION_VERBOSE = {
  [MODE_OF_TRANSPORTATION_AIR]: {
    name: 'Air',
    disabled: false,
  },
  // [MODE_OF_TRANSPORTATION_SEA]: {
  //   name: 'Sea',
  //   disabled: true,
  // },
  // [MODE_OF_TRANSPORTATION_TRUCKING]: {
  //   name: 'Trucking',
  //   disabled: true,
  // },
};

export const MODES_OF_TRANSPORTATION = Object.keys(
  MODES_OF_TRANSPORTATION_VERBOSE,
).map(modeOfTransportation => {
  return {
    value: modeOfTransportation,
    verbose: MODES_OF_TRANSPORTATION_VERBOSE[modeOfTransportation].name,
    disabled: MODES_OF_TRANSPORTATION_VERBOSE[modeOfTransportation].disabled,
  };
});

export const PACKAGE_TYPE_PARCEL = 'parcel';
export const PACKAGE_TYPE_BOX = 'box';
export const PACKAGE_TYPE_DOCUMENT = 'document';
// exclude document since it is only applied when user selects
// documents type of service
export const PACKAGE_TYPES_LIST = [PACKAGE_TYPE_PARCEL, PACKAGE_TYPE_BOX];

export const PACKAGE_SIZE_V_POUCH = 'v_pouch';
export const PACKAGE_SIZE_N_PACK_SMALL = 'n_pack_small';
export const PACKAGE_SIZE_N_PACK_LARGE = 'n_pack_large';
export const PACKAGE_SIZES_PARCEL_VERBOSE = {
  [PACKAGE_SIZE_V_POUCH]: 'Parcel (0.4 x 10.8 x 14)',
  [PACKAGE_SIZE_N_PACK_SMALL]: 'N-pack Small (14 x 9.5 x 1.75)',
  [PACKAGE_SIZE_N_PACK_LARGE]: 'N-pack Large (18 x 12 x 1.75)',
};
export const PACKAGE_SIZES_PARCEL = Object.keys(
  PACKAGE_SIZES_PARCEL_VERBOSE,
).map(packageSizeParcel => {
  return {
    value: packageSizeParcel,
    verbose: PACKAGE_SIZES_PARCEL_VERBOSE[packageSizeParcel],
  };
});
export const PACKAGE_SIZE_KILOBOX_MINI = 'kilobox_mini';
export const PACKAGE_SIZE_KILOBOX_SMALL = 'kilobox_small';
export const PACKAGE_SIZE_KILOBOX_SLIM = 'kilobobx_slim';
export const PACKAGE_SIZE_KILOBOX_MEDIUM = 'kilobox_medium';
export const PACKAGE_SIZE_KILOBOX_LARGE = 'kilobox_large';
export const PACKAGE_SIZE_KILOBOX_XL = 'kilobox_xl';
export const PACKAGE_SIZE_D1 = 'd1';
export const PACKAGE_SIZES_BOX_VERBOSE = {
  [PACKAGE_SIZE_KILOBOX_MINI]: 'Box - Mini (9 x 5 x 3)',
  [PACKAGE_SIZE_KILOBOX_SMALL]: 'Box - Small (12 x 10 x 5)',
  [PACKAGE_SIZE_KILOBOX_SLIM]: 'Box - Slim (16 x 10 x 3)',
  [PACKAGE_SIZE_KILOBOX_MEDIUM]: 'Box - Medium (14 x 10.5 x 7)',
  [PACKAGE_SIZE_KILOBOX_LARGE]: 'Box - Large (18 x 12 x 9)',
  [PACKAGE_SIZE_KILOBOX_XL]: 'Box - XL (20 x 16 x 12)',
  [PACKAGE_SIZE_D1]: 'D1',
};
export const PACKAGE_SIZES_BOX = Object.keys(PACKAGE_SIZES_BOX_VERBOSE).map(
  packageSizeBox => {
    return {
      value: packageSizeBox,
      verbose: PACKAGE_SIZES_BOX_VERBOSE[packageSizeBox],
    };
  },
);
export const PACKAGE_SIZE_DOCUMENT_REGULAR = 'document_regular';
export const PACKAGE_SIZE_DOCUMENT_XL = 'document_xl';
export const PACKAGE_SIZE_DOCUMENT_SS = 'document_ss';
export const PACKAGE_SIZES_DOCUMENT_VERBOSE = {
  [PACKAGE_SIZE_DOCUMENT_REGULAR]: 'Document Regular (11 x 6.5)',
  [PACKAGE_SIZE_DOCUMENT_XL]: 'Document XL (14 x 9.5)',
  [PACKAGE_SIZE_DOCUMENT_SS]: 'Document SS (18 x 12)',
};
export const PACKAGE_SIZES_DOCUMENT = Object.keys(
  PACKAGE_SIZES_DOCUMENT_VERBOSE,
).map(packageSizeDocument => {
  return {
    value: packageSizeDocument,
    verbose: PACKAGE_SIZES_DOCUMENT_VERBOSE[packageSizeDocument],
  };
});

export const FREIGHT_CHARGE_PAYMENT_TYPE_FREIGHT_COLLECT = 'freight_collect';
export const FREIGHT_CHARGE_PAYMENT_TYPE_PICK_UP = 'pick_up';
export const FREIGHT_CHARGE_PAYMENT_TYPE_MAYA = 'maya';
export const FREIGHT_CHARGE_PAYMENT_TYPE_GCASH = 'gcash';
export const FREIGHT_CHARGE_PAYMENT_TYPES_VERBOSE = {
  [FREIGHT_CHARGE_PAYMENT_TYPE_PICK_UP]: 'Cash on Pick-up',
  [FREIGHT_CHARGE_PAYMENT_TYPE_FREIGHT_COLLECT]: 'Freight Collect',
  [FREIGHT_CHARGE_PAYMENT_TYPE_MAYA]: 'Maya',
  [FREIGHT_CHARGE_PAYMENT_TYPE_GCASH]: 'Gcash',
};
export const DISABLED_FREIGHT_CHARGE_PAYMENT_TYPES = [
  FREIGHT_CHARGE_PAYMENT_TYPE_MAYA,
  FREIGHT_CHARGE_PAYMENT_TYPE_GCASH,
];
export const FREIGHT_CHARGE_PAYMENT_TYPES = Object.keys(
  FREIGHT_CHARGE_PAYMENT_TYPES_VERBOSE,
).map(freightChargePaymentType => {
  return {
    value: freightChargePaymentType,
    verbose: FREIGHT_CHARGE_PAYMENT_TYPES_VERBOSE[freightChargePaymentType],
    disabled: DISABLED_FREIGHT_CHARGE_PAYMENT_TYPES.includes(
      freightChargePaymentType,
    ),
  };
});

export const TRACKING_NUMBER_LENGTH = 9;

export const FILTER_STATUS_ALL = 'All';
export const TRACKING_STATUS_FOR_PICKUP = 'For Pickup';
export const TRACKING_STATUS_PICKED_UP = 'Picked Up';
export const TRACKING_STATUS_ORIGIN_WAREHOUSE = 'Origin Warehouse';
export const TRACKING_STATUS_ORIGIN_PORT = 'Origin Port';
export const TRACKING_STATUS_DESTINATION_PORT = 'Destination Port';
export const TRACKING_STATUS_DESTINATION_WAREHOUSE = 'Destination Warehouse';
export const TRACKING_STATUS_OUT_FOR_DELIVERY = 'Out for Delivery';
export const TRACKING_STATUS_DELIVERED = 'Delivered';
export const TRACKING_STATUS_CANCELED = 'Canceled Shipment';
export const TRACKING_STATUS_OPTIONS = [
  TRACKING_STATUS_FOR_PICKUP,
  TRACKING_STATUS_PICKED_UP,
  TRACKING_STATUS_ORIGIN_WAREHOUSE,
  TRACKING_STATUS_ORIGIN_PORT,
  TRACKING_STATUS_DESTINATION_PORT,
  TRACKING_STATUS_DESTINATION_WAREHOUSE,
  TRACKING_STATUS_OUT_FOR_DELIVERY,
  TRACKING_STATUS_DELIVERED,
  TRACKING_STATUS_CANCELED,
];

export const TRACKING_STATUS_UPDATE_TOGGLER_EDIT = 'Edit';
export const TRACKING_STATUS_UPDATE_TOGGLER_CANCEL = 'Cancel';
export const SHIPPING_ITEMS_DISPLAY_LIMIT = 5;
