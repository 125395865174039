import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/App.css';
import './assets/css/fonts.css';
import './assets/css/Waybills.css';

import './assets/css/components/CommonTabs.css';
import './assets/css/components/CommonTable.css';
import './assets/css/components/CommonDropdown.css';
import './assets/css/components/CustomDropdown.css';
import './assets/css/components/DateRangePicker.css';
import './assets/css/components/PageHeader.css';
import './assets/css/components/Searchbar.css';
import './assets/css/components/Timeline.css';
import './assets/css/components/Boxes.css';
import './assets/css/components/HomePage.css'

import './assets/css/admin/Users.css';
import './assets/css/admin/Shipments.css';
import './assets/css/admin/Sales.css';


import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
