import React, { useState } from 'react';
import axios from 'axios';

import SignupForm from '../../molecules/SignupForm';
import { toFormData } from '../../../utilities/collection';
import { generateReferralCode } from '../../../utilities/generators';
import { ROLE_AGENT } from '../../../utilities/constants';

const AddUsers = props => {
  const { history } = props;
  const [region, setRegion] = useState('');

  const handleSignup = data => {
    data = {
      ...data,
      region,
    };

    const userRequiresReferralCodeGeneration =
      data.role === 'PARTNER' &&
      (data.referral_code === '' || data.referral_code === null);

    if (userRequiresReferralCodeGeneration) {
      generateReferralCode(updatedReferralCode => {
        data.referral_code = updatedReferralCode;
        signup(data);
      });
    } else {
      signup(data);
    }

    function signup(data) {
      const formData = toFormData(data);

      axios
        .post('/api/user/admin', formData)
        .then(function() {
          history.push('/agent/clients');
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  };

  return (
    <div className="col">
      <div className="row">
        <div className="col create-account">
          <h2 style={{ margin: '0.5em', fontSize: '40px' }}>Add New User</h2>
          <SignupForm
            creatorRole={ROLE_AGENT}
            buttonSubmitText={'Save'}
            onSubmit={handleSignup}
            setRegion={setRegion}
            region={region}
          />
        </div>
      </div>
    </div>
  );
};

export default AddUsers;
