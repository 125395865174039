import React from 'react';
import { Link as NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { clearData } from '../../../utilities/clearData';
import { UserContext } from '../../templates/UserContext';


function UserMenu(props) {
  const handleLogout = context => {
    document.querySelector('button.btn-nav-user').click();
    context.removeUserDetails();
    window.$('#userMenu').collapse('hide');
    clearData();
    const { history } = props;
    history.push('/');
    window.location.reload()
  };

  return (
    <div id="userMenu" className="user-menu collapse">
      <NavLink to="/order-history">
        <p className="caption sub-caption">Order History</p>
      </NavLink>
      <NavLink to="/account-details">
        <p className="caption sub-caption">Account Details</p>
      </NavLink>
      <UserContext.Consumer>
        {context => (
          <button className="btn-link" onClick={() => handleLogout(context)}>
            <p className="caption sub-caption log-out">Log Out</p>
          </button>
        )}
      </UserContext.Consumer>
    </div>
  );
}

const RoutedUserMenu = withRouter(UserMenu);
export default RoutedUserMenu;
