import React, { useState } from 'react';
import CourierForm from '../molecules/CourierForm';
import axios from 'axios';
import { useLocation, Redirect } from 'react-router-dom';
import { toFormData } from '../../utilities/collection';
import { ROLE_COURIER } from '../../utilities/constants';

export default function CourierChangeStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const trackNum = searchParams.get('trackNum');

  const user = JSON.parse(localStorage.getItem('userDetails'));
  const prevLocation = useLocation();
  const redirectTo = `${prevLocation.pathname}${prevLocation.search}`;

  const handleSubmit = data => {
    setIsLoading(true);
    const formData = toFormData({
      ...data,
      proofImage: data.proofImage ? data.proofImage[0] : [],
    });
    axios
      .put(`/api/courier/shipments/${trackNum}/status_update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(_response => {
        setResponse(_response.data);
        setIsLoading(false);
      })
      .catch(err => {
        if (err.response) {
          setResponse(err.response.data);
        } else {
          setResponse({ message: 'Something went wrong.' });
        }

        setIsLoading(false);
        console.error(err);
      });
  };

  if (user?.roles?.includes(ROLE_COURIER)){
    return (
      <div className="container-fluid parallax text-center" id="top">
        <div className="col">
          <div className="row">
            <div className="col create-account">
              <h2 style={{ margin: '0.5em', fontSize: '40px' }}>Change Status Form</h2>
              <CourierForm isLoading={isLoading} response={response} onSubmit={handleSubmit} trackNum={trackNum}/>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to={`/login?redirectTo=${redirectTo}`}/>
  }
}