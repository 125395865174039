import React from 'react';

const Loader = props => (
  <div className="spinner-container">
    <div className="lds-ripple" style={props.style ? props.style : {}}>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
