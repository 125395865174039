import imageSourceParcel from '../../assets/images/parcel.png';
import imageSourceBox from '../../assets/images/box.png';
import imageSourceDocument from '../../assets/images/document.png';
import imageSourcePassport from '../../assets/images/passport.png';

export const WEIGHT_UNIT = 'kg';

export const AREA_OF_SERVICE_PROVINCE = {
  '00': 'Metro Manila',
  CEB: 'Cebu',
  MSR: 'Misamis Oriental',
  DAS: 'Davao del Sur',
  ZAS: 'Zamboanga del Sur',
  ZAN: 'Zamboanga del Norte',
  NEC: 'Negros Occidental',
  BOH: 'Bohol',
  MSC: 'Misamis Occidental',
  NER: 'Negros Oriental',
  ILI: 'Iloilo'
};

export const WHITELIST_REGION = Object.keys(AREA_OF_SERVICE_PROVINCE);

export const AREA_OF_SERVICE_CITIES = {
  '00': {},
  CEB: {
    CEB: {
      name: 'Cebu City',
      additionalCharge: 0,
    },
    MDU: {
      name: 'Mandaue City',
      additionalCharge: 0,
    },
    LPU: {
      name: 'Lapu-Lapu City',
      additionalCharge: 0,
    },
    TAY: {
      name: 'Talisay City',
      additionalCharge: 0,
    },
    CON: {
      name: 'Consolacion',
      additionalCharge: 0,
    },
  },
  MSR: {
    CGY: {
      name: 'Cagayan de Oro City',
      additionalCharge: 0,
    },
  },
  DAS: {
    DVO: {
      name: 'Davao City',
      additionalCharge: 0,
    },
  },
  ZAS: {
    ZAM: {
      name: 'Zamboanga City',
      additionalCharge: 0,
    },
  },
  ZAN: {
    DPL: {
      name: 'Dipolog City',
      additionalCharge: 0,
    },
    DAP: {
      name: 'Dapitan City',
      additionalCharge: 0,
    },
  },
  NEC: {
    BCD: {
      name: 'Bacolod City',
      additionalCharge: 0,
    },
  },
  BOH: {
    TAG: {
      name: 'Tagbilaran City',
      additionalCharge: 0,
    },
    PAN: {
      name: 'Panglao',
      additionalCharge: 0,
    },
    BAC: {
      name: 'Baclayon',
      additionalCharge: 0,
    },
    ALB: {
      name: 'Albur',
      additionalCharge: 0,
    },
    COR: {
      name: 'Corella',
      additionalCharge: 0,
    },
    CRT: {
      name: 'Cortes',
      additionalCharge: 0,
    },
  },
  MSC: {
    OZC: {
      name: 'Ozamiz City',
      additionalCharge: 0,
    },
  },
  NER: {
    DGT: {
      name: 'Dumaguete City',
      additionalCharge: 0,
    },
  },
  ILI:{
    ILO: {
      name: 'Iloilo City',
      additionalCharge: 0,
    },
  }
};

export const PACKAGE_TYPES = {
  POUCH: 'Pouch',
  BOX: 'Box',
  YOUR_BOX: 'Your Box',
  PASSPORT: 'Passport',
};

export const PACKAGE_TYPES_LIST = Object.keys(PACKAGE_TYPES);

export const PACKAGE_TYPE_VARIANTS = {
  // REGULAR_POUCH: {
  //   id: 'P-REG',
  //   name: 'Regular Pouch',
  //   maxWeight: '1',
  //   type: PACKAGE_TYPES.POUCH,
  //   basePrice: 99,
  //   boxFee: 0,
  //   sizeByInches: '7 \u00D7 10',
  //   sizeByCentimeters: '17.78 \u00D7 25',
  //   sameDayPrice: 2000,
  //   waybillName: 'Regular Pouch',
  // },
  LARGE_POUCH: {
    id: 'P-LAR',
    name: 'Large Pouch',
    maxWeight: 3,
    type: PACKAGE_TYPES.POUCH,
    basePrice: 230,
    boxFee: 0,
    sizeByInches: '11 \u00D7 16',
    sizeByCentimeters: '28 \u00D7 40.5',
    sameDayPrice: 2000,
    waybillName: 'Large Pouch',
    image: imageSourceDocument,
  },
  PASSPORT: {
    id: 'PASS',
    name: 'Passport',
    maxWeight: 1,
    type: PACKAGE_TYPES.PASSPORT,
    basePrice: 399,
    boxFee: 0,
    sizeByInches: '',
    sizeByCentimeters: '',
    sameDayPrice: 2000,
    waybillName: 'Passport Pouch',
    image: imageSourcePassport,
  },
  // MINI_BOX: {
  //   id: 'B-MIN',
  //   name: 'Mini Box',
  //   maxWeight: 1,
  //   type: PACKAGE_TYPES.BOX,
  //   basePrice: 160,
  //   boxFee: 15,
  //   sizeByInches: '5.1 \u00D7 5.1 \u00D7 3.9',
  //   sizeByCentimeters: '13 \u00D7 13 \u00D7 10',
  //   sameDayPrice: 2000,
  // },
  SMALL_BOX: {
    id: 'B-SML',
    name: 'Small Box',
    maxWeight: 3,
    type: PACKAGE_TYPES.BOX,
    basePrice: 220,
    boxFee: 26,
    sizeByInches: '12 \u00D7 10 \u00D7 5',
    sizeByCentimeters: '30.5 \u00D7 25.4 \u00D7 12.7',
    sameDayPrice: 2000,
    waybillName: 'Small Box',
    image: imageSourceBox,
  },
  /* SLIM_BOX: {
    id: 'B-SLM',
    name: 'Slim Box',
    maxWeight: 3,
    type: PACKAGE_TYPES.BOX,
    basePrice: 200,
    boxFee: 20,
  }, */
  MEDIUM_BOX: {
    id: 'B-MED',
    name: 'Medium Box',
    maxWeight: 5,
    type: PACKAGE_TYPES.BOX,
    basePrice: 370,
    boxFee: 32,
    sizeByInches: '14 \u00D7 10.5 \u00D7 7',
    sizeByCentimeters: '35.6 \u00D7 26.7 \u00D7 17.8',
    sameDayPrice: 2370,
    waybillName: 'Medium Box',
    image: imageSourceBox,
  },
  LARGE_BOX: {
    id: 'B-LAR',
    name: 'Large Box',
    maxWeight: 10,
    type: PACKAGE_TYPES.BOX,
    basePrice: 720,
    boxFee: 45,
    sizeByInches: '18 \u00D7 12 \u00D7 9',
    sizeByCentimeters: '45.7 \u00D7 30.5 \u00D7 22.9',
    sameDayPrice: 2720,
    waybillName: 'Large Box',
    image: imageSourceParcel,
  },
  // XL_BOX: {
  //   id: 'B-XLG',
  //   name: 'XL Box',
  //   maxWeight: 19,
  //   type: PACKAGE_TYPES.BOX,
  //   basePrice: 1420,
  //   boxFee: 100,
  //   sizeByInches: '20 \u00D7 16 \u00D7 12',
  //   sizeByCentimeters: '50.8 \u00D7 40.6 \u00D7 30.5',
  //   sameDayPrice: 2920,
  // },
};

export const PACKAGE_TYPES_YOUR_BOX = {
  id: 'Y-YBX',
  name: 'Your Box',
  minWeight: 5,
  type: PACKAGE_TYPES.YOUR_BOX,
  basePrice: 375,
  perKg: 75,
  surcharge: 20,
  boxFee: 0,
  sameDayPrice: 2000,
};

export const PACKAGE_TYPES_ALL = {
  ...PACKAGE_TYPE_VARIANTS,
  PACKAGE_TYPES_YOUR_BOX,
};

export const PACKAGE_TYPE_VARIANTS_TO_LIST = Object.values(
  PACKAGE_TYPE_VARIANTS,
);

export const PACKAGE_TYPES_ALL_TO_LIST = Object.values(PACKAGE_TYPES_ALL);

export function findInfoById(pid) {
  if (pid === PACKAGE_TYPES_YOUR_BOX.id) {
    return {
      id: PACKAGE_TYPES_YOUR_BOX.id,
      type: PACKAGE_TYPES_YOUR_BOX.type,
    };
  } else {
    const { name, type, waybillName } = Object.values(
      PACKAGE_TYPE_VARIANTS,
    ).find(i => i.id === pid);

    return { name, type, waybillName };
  }
}

export function findShippingPriceById(pid, pw, isSameDayDelivery) {
  const basePriceKey = isSameDayDelivery ? 'sameDayPrice' : 'basePrice';
  var price;
  var weightCharge = pw < 5 ? 0 : pw - 5;

  price = Object.values(PACKAGE_TYPE_VARIANTS).find(({ id }) => id === pid);

  if (!price && pid !== PACKAGE_TYPES_YOUR_BOX.id) {
    price = PACKAGE_TYPE_VARIANTS['LARGE_POUCH'];
  } else if (pid === PACKAGE_TYPES_YOUR_BOX.id) {
    price = {
      basePrice: PACKAGE_TYPES_YOUR_BOX.basePrice,
      sameDayPrice: PACKAGE_TYPES_YOUR_BOX.sameDayPrice,
      additionalCharge: weightCharge * PACKAGE_TYPES_YOUR_BOX.perKg,
      surcharge: PACKAGE_TYPES_YOUR_BOX.surcharge,
    };
  }

  return {
    basePrice: price[basePriceKey] || 0,
    boxFee: price?.boxFee || 0,
    additionalCharge: price?.additionalCharge || 0,
    surcharge: price?.surcharge || 0,
  };
}

export function findPackageNameById(id) {
  const packageType = Object.values(PACKAGE_TYPES_ALL).find(
    pkg => pkg.id === id,
  );
  return packageType ? packageType.name : null;
}

export function findPackageNameWithCount(id, packageCount) {
  const name = findPackageNameById(id);
  if (name) {
    packageCount[name] = (packageCount[name] || 0) + 1;
    return `${name} ${packageCount[name]}`;
  }
  return null;
}
