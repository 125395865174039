import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import isEmpty from 'lodash-es/isEmpty';

import { UserContext } from '../../templates/UserContext';

export default class NavbarRight extends Component {
  static contextType = UserContext;

  state = {
    popupVisible: false,
  };

  handleClick = () => {
    if (!this.state.popupVisible) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
      window.$('#userMenu').collapse('hide');
    }

    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible,
    }));
  };

  handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  render() {
    return (
      <UserContext.Consumer>
        {context => (
          <>
            <div
              className="nav-right collapse"
              id="rightNavbarMobile"
              ref={node => {
                this.node = node;
              }}
            >
              {!isEmpty(context.state.userDetails) && (
                <Fragment>
                  <button
                    className="btn btn-nav-user nav-right-item"
                    data-toggle="collapse"
                    data-target="#userMenu"
                    onClick={this.handleClick}
                  >
                    {context.state.userDetails.firstname ||
                      context.state.userDetails.name}{' '}
                    <span className="glyphicon glyphicon-menu-down"></span>
                  </button>
                </Fragment>
              )}

              {/* <!-- IF USER IS NOT LOGGED IN --> */}
              {isEmpty(context.state.userDetails) && (
                <Fragment>
                  <NavLink to="/login" className="nav-right-item">
                    Login
                  </NavLink>
                  <span> | </span>
                  <NavLink to="/signup" className="nav-right-item">
                    Sign Up
                  </NavLink>
                </Fragment>
              )}
            </div>
            <div
              className="nav-right"
              id="rightNavbar"
              // ref={node => {
              //   this.node = node;
              // }}
            >
              {!isEmpty(context.state.userDetails) && (
                <Fragment>
                  <button
                    className="btn btn-nav-user nav-right-item"
                    data-toggle="collapse"
                    data-target="#userMenu"
                    onClick={this.handleClick}
                  >
                    {context.state.userDetails.firstname ||
                      context.state.userDetails.name}{' '}
                    <span className="glyphicon glyphicon-menu-down"></span>
                  </button>
                </Fragment>
              )}

              {/* <!-- IF USER IS NOT LOGGED IN --> */}
              {isEmpty(context.state.userDetails) && (
                <Fragment>
                  <NavLink to="/login" className="nav-right-item">
                    Login
                  </NavLink>
                  <span> | </span>
                  <NavLink to="/signup" className="nav-right-item">
                    Sign Up
                  </NavLink>
                </Fragment>
              )}
            </div>
          </>
        )}
      </UserContext.Consumer>
    );
  }
}
