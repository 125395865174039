import React from 'react';
import '../../assets/css/components/SecondaryNavbar.css';
import { Link as NavLink } from 'react-router-dom';

function PartnerSecondaryNavbar() {
  return (
    <nav className="secondary-navbar">
      <ul className="secondary-navbar-items">
        <li>
          <NavLink to="/partner/shipments">
            <span className="secondary-navbar-items-span">Shipments</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default PartnerSecondaryNavbar;
