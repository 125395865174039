import React from 'react';

const PageHeader = props => {
  const { title } = props;

  return (
    <div className="pageheader-container">
      <h3 className="pageheader-title">{title}</h3>
    </div>
  );
};

export default PageHeader;
