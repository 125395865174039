import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { TRACKING_STATUS_OPTIONS } from '../../utilities/constants/shipping';
import Loader from '../atoms/Loader';
import axios from 'axios';

function CourierForm(props) {
  const { isLoading, response, onSubmit, trackNum } = props;
  const { register, handleSubmit, setValue, watch } = useForm();
  const [showUpload, setShowUpload] = useState(false);
  let shipmentData;

  const user = JSON.parse(localStorage.getItem('userDetails'));

  const handleStatusChange = e => {
    const statusValue = e.target.value;
    if (statusValue === 'Delivered') {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  };

  const renderUploadButton = showUpload && (
    <input
      type="file"
      accept="image/*"
      name="proofImage"
      ref={register({
        required: true,
      })}
      id="contained-button-file"
    />
  );

  const renderOptions = TRACKING_STATUS_OPTIONS.map(trackingStatusOption => {
    return (
      <option key={trackingStatusOption} value={trackingStatusOption}>
        {trackingStatusOption}
      </option>
    );
  });

  const handleUpdateClick = () => {
    axios
      .put(
        `/api/admin/shipments/${shipmentData.id}/status_update`,
        {
          tracking_status: watch('status'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .catch(err => console.log(err));
  };

  useEffect(() => {
    const fetchShipmentData = async () => {
      try {
        const shipmentResponse = await axios.get(`/api/courier/shipments/${trackNum}`);
        shipmentData = shipmentResponse.data.payload[0];
        setValue('location', shipmentData.location);
        setValue('status', shipmentData.tracking_status);
      } catch (error) {
        console.error(error);
      }
    };
  
    if (trackNum) {
      fetchShipmentData();
    }
  }, [trackNum, setValue]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {response.message && (
        <p
          style={{
            color: response.code === 200 ? 'green' : 'red',
            fontSize: 15,
          }}
        >
          {response.message}
        </p>
      )}
      {response?.code !== 200 && (
        <form className="courier-form" onSubmit={handleSubmit(data => {
          onSubmit(data);
          handleUpdateClick(); 
        })}>
          <label htmlFor="tracking-number">
            <h4>Tracking Number</h4>
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            defaultValue={trackNum}
          />
          <label htmlFor="name">
            <h4>Name</h4>
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            id="courierName"
            name="courierName"
            defaultValue={`${user.firstname} ${user.lastname}`}
            ref={register({ required: true })}
          />

          <label htmlFor="location">
            <h4>Location</h4>
          </label>
          <input
            type="text"
            className="form-control"
            id="location"
            name="location"
            ref={register({ required: true })}
          />

          <label htmlFor="status">
            <h4>Status</h4>
          </label>
          <select
            id="status"
            name="status"
            ref={register({
              required: true,
            })}
            onChange={handleStatusChange}
          >
            {renderOptions}
          </select>

          {renderUploadButton}

          <button type="submit" className="btn">
            Change Status
          </button>
        </form>
      )}
    </>
  );
}

export default CourierForm;