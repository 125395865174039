import { setTraceId } from './';
import axios from 'axios';
import { getLocalStorageItem } from '../utilities/localStorage.util';

import { UserContext } from '../components/templates/UsersContext';

const createAxiosInstance = (baseURL) => {
  const instance = axios.create(
    setTraceId({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  );

  instance.interceptors.request.use(
    async (config) => {
      const credentials = await getLocalStorageItem('userDetails');
     
      if (credentials && credentials.access_token) {
        config.headers['Authorization'] = `Bearer ${credentials.access_token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add an interceptor to handle 401 Unauthorized responses
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 here by logging out the user
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

const axiosInstance = createAxiosInstance();

export const getAPI = async (url, config) => {
  return axiosInstance.get(url, config);
};

export const postAPI = async (url, data, config) => {
  return axiosInstance.post(url, data, config);
};

export const putAPI = async (url, data, config) => {
  return axiosInstance.put(url, data, config);
};

export const delAPI = async (url, config) => {
  return axiosInstance.delete(url, config);
};

export default axiosInstance;
