import React, { useState, useEffect } from 'react';

import DateRangePicker from '../../../atoms/DateRangePicker/DateRangePicker';
import CustomDropdown from '../../../atoms/CustomDropdown/CustomDropdown';

const SalesFilter = props => {
  const { provinces, agents, handleFilterChanges } = props;

  const [agentFilter, setAgentFilter] = useState('');
  const [provinceFilters, setProvinceFilters] = useState({});
  const [dateRange, setDateRange] = useState([
    `${new Date().getFullYear()}-01-01`,
    `${new Date().getFullYear()}-12-31`,
  ]);

  const years = Array.from(
    { length: new Date().getFullYear() - 2022 },
    (_, index) => {
      const year = new Date().getFullYear() - index;
      return {
        label: `${year}`,
        value: `${year}`,
      };
    },
  );

  useEffect(() => {
    handleFilterChanges({
      dateRange,
      province: provinceFilters,
      agent: agentFilter,
    });
  }, [dateRange, provinceFilters, agentFilter]);

  const handleProvinceFilterChange = value => {
    setProvinceFilters(value);
  };
  const handleAgentFilterChange = value => {
    setAgentFilter(value);
  };

  const handleDateRangeSelect = newDateRange => {
    const nonEmptyDateRange = newDateRange.filter(date => date);
    setDateRange(nonEmptyDateRange);
  };

  const handleYearFilterChange = year => {
    const wholeYearDateRange = [`${year.value}-01-01`, `${year.value}-12-31`];
    setDateRange(wholeYearDateRange);
  };

  return (
    <>
      <div className="sales-filter-container">
        <div className="sales-filter-dropdowns">
          <CustomDropdown
            placeholder="Filter by Year"
            options={years || []}
            onChange={handleYearFilterChange}
            defaultValue={years[0]}
            hideAll
          />
        </div>
        <div className="sales-filter-dropdowns">
          <DateRangePicker onChange={handleDateRangeSelect} />
        </div>
        <div className="sales-filter-dropdowns">
          <CustomDropdown
            placeholder="Filter by Province"
            options={provinces || []}
            onChange={handleProvinceFilterChange}
          />
        </div>
        <div className="sales-filter-dropdowns">
          <CustomDropdown
            placeholder="Filter by Account"
            options={agents?.items || []}
            onChange={handleAgentFilterChange}
          />
        </div>
      </div>
    </>
  );
};

export default SalesFilter;
