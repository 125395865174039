import React from 'react';
import { useHistory } from 'react-router-dom';

function CourierStatusChangeSuccess(){
    const history = useHistory()
    const handleClick = () => {
        history.push('/courier')
    };

    return (
        <div className="container-fluid parallax text-center" id="top">
          <div className="col">
            <div className="row">
              <div className="col create-account">
                <h2 style={{ margin: '0.5em', fontSize: '40px' }}>
                  Status Change Success
                </h2>
                <button className="btn" type="button" onClick={handleClick}>
                  Back to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      );
}

export default CourierStatusChangeSuccess;