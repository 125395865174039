import { CSVLink } from 'react-csv';
import axios from 'axios';
import { useEffect, useState } from 'react';

const Export = ({ startDate, endDate, children, ...props }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `/api/admin/shipments/range?start_date=${startDate}&end_date=${endDate}`,
      )
      .then(response => {
        const newData = response.data.payload;
        const generatedData = newData.map(item => ({
          created: item.created,
          charge_payment: item.charge_payment,
          charges: JSON.stringify(item.charges).replace(
            /,(?![^{}[\]]*["}\]])/g,
            ';',
          ),
          consignee_address: item.consignee_address,
          consignee_brgy: item.consignee_brgy,
          consignee_city: item.consignee_city,
          consignee_company_name: item.consignee_company_name,
          consignee_contact: item.consignee_contact,
          consignee_country: item.consignee_country,
          consignee_email: item.consignee_email,
          consignee_name: item.consignee_name,
          consignee_province: item.consignee_province,
          consignee_zip_code: item.consignee_zip_code,
          currency: item.currency,
          declared_value: item.declared_value,
        }));

        console.log({ generatedData });
        setData(generatedData);
      })
      .catch(error => {
        console.error(error);
      });
  }, [startDate, endDate]);

  return (
    <CSVLink
      data={data}
      filename={`Sales_${startDate}_${endDate}.csv`}
      {...props}
    >
      {children}
    </CSVLink>
  );
};

export default Export;
