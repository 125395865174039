import React from 'react';
import '../../assets/css/components/InputCheckbox.css'

function InputCheckbox(props) {
  const { isChecked, handleOnChange, checkboxLabel, disabled = false } = props;
  return (
    <>
      <label className={`input-checkbox-label form-control ${disabled ? 'disabled' : ''}`}>
        <input
          id="input-checkbox"
          name="chbx"
          type="checkbox"
          checked={isChecked}
          onChange={handleOnChange}
          disabled={disabled}
        /> 
        {checkboxLabel}
      </label>
    </>
  )
}

export default InputCheckbox;