import React, { Component } from 'react';
import axios from 'axios';

import EditUserForm from '../../molecules/EditUserForm';
import { UsersContext } from '../../templates/UsersContext';
import { toFormData } from '../../../utilities/collection';
import DeleteConfirmationModal from '../../organisms/DeleteConfirmationModal';
import { ROLE_AGENT } from '../../../utilities/constants';

class EditUsers extends Component {
  static contextType = UsersContext;
  state = {
    user: {},
    region: '',
    isDeleteModalVisible: false,
  };

  componentDidMount() {
    let self = this;
    const { id } = this.props.match.params;
    axios
      .get(`/api/user/${id}`)
      .then(function(response) {
        self.setState({
          user: response.data.payload,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handleSignup = data => {
    let { history } = this.props;

    data = {
      ...data,
      region: this.state.region,
    };

    const formData = toFormData(data);
    const { id } = this.props.match.params;
    axios
      .post(`/api/user/${id}`, formData)
      .then(function(response) {
        history.push('/admin/users');
      })
      .catch(function(error) {
        alert(error.response.data.message);
      });
  };

  handleDelete = data => {
    let { history } = this.props;
    const formData = toFormData(data);
    const { id } = this.props.match.params;
    axios
      .delete(`/api/user/admin/delete/${id}`, formData)
      .then(function(response) {
        history.push('/admin/users');
      })
      .catch(function(error) {
        alert(error.response.data.message);
      });
  };

  toggleConfirmationModal = () => {
    this.setState(prevState => ({
      isDeleteModalVisible: !prevState.isDeleteModalVisible,
    }));
  };

  render() {
    return (
      <div className="col">
        {this.state.isDeleteModalVisible && (
          <DeleteConfirmationModal
            toggleConfirmationModal={this.toggleConfirmationModal}
            handleDelete={this.handleDelete}
          />
        )}
        <div className="row">
          <div className="col create-account">
            <h2 style={{ margin: '0.5em', fontSize: '40px' }}>Edit User</h2>
            <EditUserForm
              onSubmit={this.handleSignup}
              onDelete={this.handleDelete}
              user={this.state.user}
              setRegion={value => this.setState({ region: value })}
              region={this.state.region}
              toggleConfirmationModal={this.toggleConfirmationModal}
              creatorRole={ROLE_AGENT}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EditUsers;
